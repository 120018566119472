import commonMixins from './common'

export default {
  mixins: [commonMixins],
  created() {
    this.initDisplayNotify()
  },
  computed: {
    // 针对Array的父元素
    arrayParent() {
      let parent = this.$parent

      if (!parent) {
        return parent
      }
      let parentName = parent.$options.name

      while (parentName !== 'FuMinArray') {
        if (!parent.$parent) {
          return parent
        }
        parent = parent.$parent
        parentName = parent.$options.name
      }
      return parent
    }
  },
  methods: {
    initDisplayNotify() {
      if (!this.fieldJson.show) { return }
      if (!this.fieldJson.displayRely) { return }
      if (!this.fieldJson.displayRelyGather || !this.fieldJson.displayRelyGather.length) { return }

      const displayRelyGather = this.fieldJson.displayRelyGather

      // 初始化的时候先计算一次是否显示
      this.ifRender = this.judgeIfRender()
      // this.arrayParent.isRenderHeader = this.ifRender

      // 添加监听关联字段变化
      this.formStore.subscribe((name) => {
        // 判断当前变动的字段是否是关联字段
        if (!this.hasRelyField(name, displayRelyGather)) { return }

        // 最终的判断结果直接决定是否显示
        this.ifRender = this.judgeIfRender()
        // this.arrayParent.isRenderHeader = this.ifRender
      })
    },
    judgeIfRender() {
      const displayRelyGather = this.fieldJson.displayRelyGather
      let displayRes = true

      for (let i = 0; i < displayRelyGather.length; i++) {
        const item = displayRelyGather[i]

        // 如果是 或 关系，则判断之前的结果是不是 true
        // 如果是 true 就直接返回 true
        // 如果是 false 就继续判断
        if (item.type == 'or') {
          if (displayRes) {
            return displayRes
          } else {
            displayRes = this.judgeByConditions(item.list)
          }
        } else {
          // 如果是 且 的关系，就判断之前的结果
          // 如果是 true 就继续往下判断
          // 如果是 false 就直接终止
          if (displayRes) {
            displayRes = this.judgeByConditions(item.list)
          } else {
            return displayRes
          }
        }
      }

      // 组件隐藏
      if (!displayRes) {
        // 当值和组件的显示隐藏关联的时候
        // 当组件隐藏的时候值也会清空
        if (this.fieldJson.displayRelyValue && this.currentValue) {
          if (this.isSchemaObject) {
            if (this.data.schema.type === 'object') {
              Object.keys(this.currentValue).forEach(key => {
                this.formStore.setFieldValue([...this.currentPath, key], '')
              })
              this.setCurrentValue({})
            } else {
              this.currentValue.forEach((item, index) => {
                const delItem = this.currentValue[index]
                Object.keys(delItem).forEach(key => {
                  this.formStore.setFieldValue([...this.currentPath, index, key], '')
                })
              })
              this.setCurrentValue([])
            }
          } else {
            this.setCurrentValue('')
          }
        }

        // 当值不关联字段的显示隐藏
        // 并缺配置了 在隐藏的时候需要初始化默认值的情况下 需要做默认值的初始化操作
        // TODO: 这里需要优化 把获取默认值的方法提取出来
        if (!this.fieldJson.displayRelyValue && this.fieldJson.hasInitDefaultValueOnHiding) {
          // 固定默认值
          if (this.fieldJson.defaultValueSource == 1) {
            if (this.fieldJson.defaultValue) {
              this.setCurrentValue(this.fieldJson.defaultValue)
            }
          } else if (this.fieldJson.defaultValueSource == 3 && this.fieldJson.defaultValueOriginUrl) { // 通过接口获取默认值
            const originParams = this.fieldJson.defaultValueOriginParams || []
            // 获取参数
            const params = this.getParams(originParams)
            // 所有参数都有值的情况下才会查询
            if (this.isAllParamsValued(params, originParams)) {
              this.getDefaultValue(params)
            }
          }
        }
      }

      return displayRes
    }
  }
}
