<template>
  <div
    v-if="ifRender"
    class="fu-min-form-item"
    :class="{edit: canEdit}"
  >
    <FuAlert v-bind="attrsCfg" :on-handle="onHandle" />
  </div>
</template>

<script>
import commonMixins from '../mixins/materials'
import displayMixins from '../mixins/display'
import FuAlert from '@/components/v2/FuAlert'

export default {
  name: 'FormItemAlert',
  components: { FuAlert },
  directives: { },
  mixins: [commonMixins, displayMixins],
  data() {
    return {

    }
  },
  computed: {
    isEn() {
      return this.parent.lang === 'en'
    },
    attrsCfg() {
      const { hasTitle, title, titleLocal, hasMessage, message, messageLocal, icon, close, hasHandle, handleText, type } = this.fieldJson

      return {
        title: hasTitle ? (this.isEn ? title : titleLocal || title) : '',
        text: hasMessage ? (this.isEn ? message : messageLocal || message) : '',
        icon: icon ? 'info' : '',
        close: !!close,
        handle: hasHandle ? handleText || '' : '',
        type: type || 'info'
      }
    }
  },
  mounted() {
    this.alertInit()
  },
  methods: {
    alertInit() {
      const { handleBlock } = this.fieldJson

      // 当前 弹窗有确认按钮的情况下是否阻断流程
      if (handleBlock) {
        this.$emit('block', false)
      }
    },
    onHandle() {
      const { handleBlock } = this.fieldJson
      // 当前 弹窗有确认按钮的情况下是否阻断流程
      if (handleBlock) {
        this.$emit('block', true)
      }
    }
  }
}
</script>
