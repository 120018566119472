<template>
  <div
    v-if="ifRender"
    class="fu-min-form-item"
    :class="{edit: canEdit}"
  >
    <!-- 分割组件 -->
    <FuDivider v-if="isV2 && fieldJson.divider" :type="fieldJson.dividerType || 'divider'" />

    <!-- 子标题 -->
    <p v-if="isV2 && aliasLabel" class="fu-min-form-aliaslabel">{{ aliasLabel }}</p>

    <Field
      class="no-border"
      :label="label"
      :placeholder="placeholder"
      :name="data.name + path"
      :rules="rules"
      v-bind="fieldJson"
    >
      <!-- 自定义 label -->
      <template v-if="fieldJson.tip" slot="label">
        <FuFormLabel
          :parent="parent"
          :label="label"
          :field-json="fieldJson"
        />
      </template>

      <template #input>
        <CheckboxGroup
          v-if="fieldJson.isGroup"
          v-model="currentValue"
          class="fu-form-min-checkbox-group"
          v-bind="fieldJson"
          :disabled="parent.isFormReadOnly || fieldJson.disabled"
        >
          <Checkbox
            v-for="(item, index) in options"
            :key="index"
            :name="item.value"
            shape="square"
            checked-color="#C82A21"
          >{{ item[optionsLabel] }}</Checkbox>
        </CheckboxGroup>

        <Checkbox
          v-else
          v-model="currentValue"
          shape="square"
          v-bind="fieldJson"
          :disabled="parent.isFormReadOnly || fieldJson.disabled"
        >{{ options[0] ? options[0][optionsLabel] : '' }}</Checkbox>
      </template>
    </Field>

    <FuFormNote
      v-if="!parent.isFormReadOnly"
      :lang="parent.lang"
      :field-json="fieldJson"
    />
  </div>
</template>

<script>
import commonMixins from '../mixins/materials'
import displayMixins from '../mixins/display'
import optionsSourceMixins from '../mixins/optionsSource'
import optionsRelyMixins from '../mixins/optionsRely'

export default {
  name: 'FormItemSwitch',
  mixins: [commonMixins, displayMixins, optionsSourceMixins, optionsRelyMixins],
  data() {
    return {

    }
  },
  computed: {
    options() {
      return this.fieldJson.options || []
    }
  }
}
</script>

<style lang="scss">
.fu-form-min-checkbox-group {
  .van-checkbox {
    margin: 8px 10px;
  }
}
</style>
