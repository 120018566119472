// import { propEq, find } from 'ramda'
import { getAnyTimestamp } from '../utils/index'
import dayjs from 'dayjs'
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)
import { toastLoading } from '@/utils'
import { dispatchAction } from '@/utils/dispatch'
import { codeMap } from '../../../utils/codeMap'
// import { getOcrResult } from '../../../views/new/pricing/api'
const getOcrResult = () => {}

export default {
  data() {
    return {
    }
  },
  created() {
  },
  computed: {
    enabledOCR() {
      const ocrSwitch = this.fieldJson.ocrSwitch
      if (ocrSwitch == undefined) return false
      if ([1, 2, 3, 4].includes(ocrSwitch[0]) && this.ocrParams.fieldsList) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    ocrScanning() {
      // this.testingFill()
      dispatchAction({
        method: 'uploadOCR',
        params: {
          documentTypeList: this.fieldJson.ocrSwitch
        },
        callback: (res) => {
          if (!res) {
            return
          }
          const { fileKey, documentType } = res
          if (fileKey == undefined) return
          this.autoFill(fileKey, documentType)
        }
      })
    },
    testingFill() {
      var result = {
        'dateOfBirth': '',
        'expiryDate': '',
        'gender': 'Nam',
        'firstName': 'CT',
        'fullName': 'CT TNHH TB CÔNG NGHỆ VINATECH',
        'issueDate': '12/3021',
        'idNumber': '010010000000',
        'lastName': 'TNHH TB CÔNG NGHỆ VINATECH',
        'placeOfIssue': 'Hà Nội',
        'province': 'Ngọc Chi,Vĩnh Ngọc,Đông Anh,HN',
        'vehicleBrand': 'Mitsubishi',
        'vehicleChassisNumber': 'RLA1TGF2XK1001189',
        'vehicleEngineNumber': 'BL40954B11',
        'vehicleFirstRegistrationDate': '',
        'vehicleModel': 'OUTLANDER',
        'vehiclePendingPlateNumber': 'False',
        'vehiclePlateNumber': '30G-119.49'
      }
      const ocrMapping = codeMap().ocrMapping
      this.ocrParams.fieldsList.map((item) => {
        if (!item.path) {
          return
        }
        if (this.currentPath[0] == item.path[0]) {
          var replacedPath = [...item.path]
          this.currentPath.forEach((e, index) => {
            replacedPath[index] = e
          })

          var value = ''
          // Identity No 有特殊处理 且不在ocrMapping中
          var itemKey = replacedPath[replacedPath.length - 1] == 'identityNo' ? 'identityNo' : ocrMapping[replacedPath[replacedPath.length - 1]]
          if (result[itemKey] == undefined && itemKey != 'identityNo') return

          if (itemKey == 'vehicleFirstRegistrationDate' || itemKey == 'dateOfBirth' || itemKey == 'expiryDate') {
            var date = dayjs(result[itemKey], 'DD/MM/YYYY').format('YYYY-MM-DD')
            value = getAnyTimestamp(date)
          } else if (itemKey == 'gender') {
            if (result[itemKey] == 'Nam') {
              value = 1
            } else if (result[itemKey] == 'Nữ') {
              value = 2
            } else if (result[item] == 'Male' || result[item] == 'Female') {
              value = result[itemKey] == 'Male' ? 1 : 2
            } else {
              value = ''
            }
          } else if (itemKey == 'vehicleBrand' || itemKey == 'vehicleModel') {
            value = result[itemKey].toUpperCase()
          } else if (itemKey == 'nationality') {
            value = 'vnm'
          } else if (itemKey == 'identityNo') {
            value = result['passportId'] || result['idNumber']
          } else if (itemKey == 'firstName' || itemKey == 'lastName') {
            // 名字需特殊处理
            var tempPath = replacedPath.filter(e => e !== 'name')
            this.formStore.setFieldValue(tempPath, result[itemKey])
            return
          } else {
            value = result[itemKey]
          }
          if (this.objectItems) {
            this.setCurrentValueOCR(value, replacedPath, this.getNestedValue(this.objectItems.length == 1 ? this.objectItems[0].columns : this.objectItems, replacedPath))
          } else {
            this.setCurrentValueOCR(value, replacedPath)
          }
        }
      })
    },
    getNestedValue(array, path) {
      return array.find((item) => item.key == path[path.length - 1]) || null
    },
    async autoFill(fileKey, documentType) {
      const params = {
        'documentFileKey': fileKey,
        'documentType': documentType,
        'businessSource': this.$route.meta.title,
        'businessId': this.$route.params.rfqNo ?? ''
      }

      this.loading = toastLoading()
      var result = await getOcrResult(params)
      this.loading.clear()
      this.loading = null
      if (result.code) {
        this.$router.push(`/ocr/error`)
      }

      const ocrMapping = codeMap().ocrMapping

      this.ocrParams.fieldsList.map((item) => {
        if (!item.path) {
          return
        }
        if (this.currentPath[0] == item.path[0]) {
          var replacedPath = [...item.path]
          this.currentPath.forEach((e, index) => {
            replacedPath[index] = e
          })

          var value = ''
          // Identity No 有特殊处理 且不在ocrMapping中
          var itemKey = replacedPath[replacedPath.length - 1] == 'identityNo' ? 'identityNo' : ocrMapping[replacedPath[replacedPath.length - 1]]
          if (result[itemKey] == undefined && itemKey != 'identityNo') return

          if (itemKey == 'vehicleFirstRegistrationDate' || itemKey == 'dateOfBirth' || itemKey == 'expiryDate') {
            var date = dayjs(result[itemKey], 'DD/MM/YYYY').format('YYYY-MM-DD')
            value = getAnyTimestamp(date)
          } else if (itemKey == 'gender') {
            if (result[itemKey] == 'Nam') {
              value = 1
            } else if (result[itemKey] == 'Nữ') {
              value = 2
            } else if (result[item] == 'Male' || result[item] == 'Female') {
              value = result[itemKey] == 'Male' ? 1 : 2
            } else {
              value = ''
            }
          } else if (itemKey == 'vehicleBrand' || itemKey == 'vehicleModel') {
            value = result[itemKey].toUpperCase()
          } else if (itemKey == 'nationality') {
            value = 'vnm'
          } else if (itemKey == 'identityNo') {
            value = result['passportId'] || result['idNumber']
          } else if (itemKey == 'firstName' || itemKey == 'lastName') {
            // 名字需特殊处理
            var tempPath = replacedPath.filter(e => e !== 'name')
            this.formStore.setFieldValue(tempPath, result[itemKey])
            return
          } else {
            value = result[itemKey]
          }
          if (this.objectItems) {
            this.setCurrentValueOCR(value, replacedPath, this.getNestedValue(this.objectItems.length == 1 ? this.objectItems[0].columns : this.objectItems, replacedPath))
          } else {
            this.setCurrentValueOCR(value, replacedPath)
          }
        }
      })
    }
  }
}
