<template>
  <Popup
    v-model="showPopup"
    class="fu-v2-popup"
    :class="{ 'fu-v2-popup-web': isWeb, id: isID }"
    round
    get-container="#app"
    :position="position ? position : isID ? 'bottom' : 'center'"
    :style="{ height: height }"
    :safe-area-inset-bottom="true"
    :close-on-popstate="true"
    :close-on-click-overlay="closeOnClickOverlay"
    :closeable="isID ? false : closeable"
    @close="onClose"
  >
    <!-- V2 版本新样式 -->
    <p v-if="isID" class="fu-v2-popup-top" />

    <div v-if="!isID && showIcon" class="fu-v2-popup-icon" />
    <p v-if="title || (closeable && isID)" class="fu-v2-popup-title">
      <span v-if="title">{{ title }}</span>
      <Icon
        v-if="closeable && isID"
        name="cross"
        @click="showPopup = false"
      />
    </p>
    <p v-if="desc" class="fu-v2-popup-desc">{{ desc }}</p>
    <slot />

    <div v-if="footer" class="popup-footer">
      <Button
        v-if="cancel"
        v-bind="buttonAttr"
        :loading="loading"
        :class="`fu-v2-btn ${cancelClass}`"
        @click="handleCancel"
      >{{ cancelText ? cancelText : $t('popupNo') }}</Button>
      <Button
        v-if="confirm"
        :loading="loading"
        v-bind="buttonAttr"
        :class="`fu-v2-btn danger ${confirmClass}`"
        @click="handleConfirm"
      >{{ confirmText ? confirmText : $t('popupYes') }}</Button>
    </div>
  </Popup>
</template>

<script>
import { isFunction } from '@fu/core'
import { getTenantRegion } from '@fu/core'
import { Popup, Button, Icon } from 'vant'
import { isWeb } from '@/utils/dispatch'

export default {
  name: 'FuPopup',
  components: { Popup, Button, Icon },
  props: {
    value: { type: Boolean, default: false },
    showIcon: { type: Boolean, default: true },
    closeable: { type: Boolean, default: false },
    height: { type: String, default: 'auto' },
    title: { type: String, default: '' },
    desc: { type: String, default: '' },

    footer: { type: Boolean, default: false },
    cancel: { type: Boolean, default: true },
    confirm: { type: Boolean, default: true },
    cancelText: { type: String, default: '' },
    confirmText: { type: String, default: '' },
    cancelClass: { type: String, default: '' },
    confirmClass: { type: String, default: '' },

    onCancel: { type: [String, Function], default: '' },
    onConfirm: { type: [String, Function], default: '' },

    showBottom: { type: Boolean, default: false },
    closeOnClickOverlay: { type: Boolean, default: true },
    position: { type: String, default: '' },
    buttonAttr: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
      showPopup: false,
      isWeb
    }
  },
  computed: {
    isID() {
      return getTenantRegion() === 'ID' || this.showBottom || this.$route.query.tag === 'boss'
    }
  },
  watch: {
    value(val) {
      this.showPopup = val
    }
  },
  methods: {
    onClose() {
      this.$emit('input', false)
    },

    async handleCancel() {
      if (isFunction(this.onCancel)) {
        if (this.loading) { return }

        this.loading = true
        const res = await this.onCancel()
        this.loading = false

        if (res === false) {
          return
        }
      }

      this.onClose()
    },
    async handleConfirm() {
      if (isFunction(this.onConfirm)) {
        if (this.loading) { return }

        this.loading = true
        const res = await this.onConfirm()
        this.loading = false

        if (res === false) {
          return
        }
      }

      this.onClose()
    }
  }
}
</script>

<style lang="scss">
.fu-v2-popup {
  padding: 20px;
  color: #000;

  &.id {
    padding-top: 0;
  }

  &.fixed-footer {
    padding-bottom: 75px;
  }

  .fu-v2-popup-title {
    padding: 0;
    margin-bottom: 14px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    overflow: hidden;
  }

  .fu-v2-popup-desc {
    margin-bottom: 14px;
    font-size: 14px;
    line-height: 15px;
    overflow: hidden;
  }

  .fu-v2-popup-icon {
    margin: 20px auto;
    width: 84px;
    height: 84px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFE8E8;
    border-radius: 84px;

    &::before {
      content: "i";
      display: block;
      width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 40px;
      background-color: #D90109;
      color: #fff;
      text-align: center;
      font-size: 30px;
      font-weight: 900;
    }
  }

  .popup-footer {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;

    .fu-v2-btn {
      margin: 0;
      width: 48%;

      &.block {
        width: 100%;
      }
    }

    .fu-v2-btn.block + .fu-v2-btn {
      margin-top: 10px;
    }
  }
  &.fu-v2-popup-web {
    max-width: 400px;
  }
}
</style>
