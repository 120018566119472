<template>
  <div
    v-if="ifRender"
    class="fu-min-form-item fu-min-form-ipt-item fu-min-form-textarea-item"
    :class="{edit: canEdit, focus: isV2 && isFocus}"
  >
    <!-- 分割组件 -->
    <FuDivider v-if="isV2 && fieldJson.divider" :type="fieldJson.dividerType || 'divider'" />

    <!-- 标题 -->
    <p v-if="isV2 && label && placeholder" class="fu-form-v2-label">{{ label }}</p>
    <!-- 子标题 -->
    <p v-if="isV2 && aliasLabel" class="fu-min-form-aliaslabel">{{ aliasLabel }}</p>

    <Field
      ref="inputField"
      v-model="currentValue"
      v-inputScroll
      class="prefix-split"
      :rows="fieldJson.rows || 3"
      autosize
      type="textarea"
      :label="isV2 ? (placeholder || label) : label"
      :placeholder="isV2 ? '' : placeholder"
      clearable
      :name="data.name + path"
      :rules="rules"
      :readonly="parent.isFormReadOnly && !canEdit"
      :disabled="finalDisabled"
      v-bind="fieldJson"
      @focus="onIptFocus"
      @blur="onIptBlur"
      @click="onClickField"
    >
      <!-- 自定义 label -->
      <template v-if="fieldJson.tip" slot="label">
        <FuFormLabel
          :parent="parent"
          :label="label"
          :field-json="fieldJson"
        />
      </template>
    </Field>

    <FuFormNote
      v-if="!parent.isFormReadOnly"
      :lang="parent.lang"
      :field-json="fieldJson"
    />
  </div>
</template>

<script>
import commonMixins from '../mixins/materials'
import displayMixins from '../mixins/display'
import inputMixins from '../mixins/input'
import inputScroll from '../directive/inputScroll/index'

export default {
  name: 'FormItemTextarea',
  components: {},
  directives: { inputScroll },
  mixins: [commonMixins, displayMixins, inputMixins],
  data() {
    return {

    }
  }
}
</script>
