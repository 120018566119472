<template>
  <div class="fu-v2-divider" :class="type">
    <span v-if="type === 'line' && !!content" class="fu-v2-divider-content">{{ content }}</span>
  </div>
</template>

<script>

export default {
  name: 'FuDivider',
  props: {
    type: { type: String, default: 'divider' }, // divider | line
    content: { type: String, default: '' }
  }
}
</script>

<style lang="scss">
.fu-v2-divider {
  width: 100%;
  height: 8px;
  background-color: #F5F5F5;

  &.line {
    position: relative;
    height: 1px;
    background-color: #D3D3D3;

    .fu-v2-divider-content {

    }
  }
}
</style>
