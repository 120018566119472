import FuMinObject from '../materials/object.vue'
import FuMinArray from '../materials/array.vue'
import FuMinInput from '../materials/input.vue'
import FuMinSelect from '../materials/select.vue'
import FuMinDate from '../materials/date.vue'
import FuMinUpload from '../materials/upload.vue'
import FuMinNumeric from '../materials/numeric.vue'
import FuMinAddress from '../materials/address.vue'
import FuMinCheckbox from '../materials/checkbox.vue'
import FuMinRadio from '../materials/radio.vue'
import FuMinSlider from '../materials/slider.vue'
import FuMinAiphoto from '../materials/aiphoto.vue'
import FuMinTextarea from '../materials/textarea.vue'

export default {
  components: { FuMinObject, FuMinArray, FuMinInput, FuMinSelect, FuMinDate, FuMinUpload, FuMinNumeric, FuMinAddress, FuMinCheckbox, FuMinRadio, FuMinSlider, FuMinAiphoto, FuMinTextarea }
}
