<template>
  <div class="footer fu-fixed">
    <Button
      color="#C82A21"
      :disabled="!!loading"
      :loading="loading"
      @click="onClick"
    >
      {{ name }}
    </Button>
  </div>
</template>

<script>
import { Button } from 'vant'

export default {
  name: 'FuFooter',
  components: { Button },
  props: {
    name: { type: String, default: '' },
    loading: { type: Boolean, default: false }
  },
  data() {
    return { }
  },
  methods: {
    onClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.footer{
  position: fixed;
  z-index: 11;
  bottom: 0;
  left: 0;
  padding: 12px 40px;
  padding-bottom: 22px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px -2.5px 10px rgba(0, 0, 0, 0.1);

  display: flex;
  justify-content: center;

  .van-button{
    background-color: #C82A21;
    box-shadow: 0px 0.714988px 2.85995px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    width: 100%;
    height: 46px;
    line-height: 46px;
    text-align: center;
    overflow: hidden;

    .van-button__text{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      color: #FFFFFF;
    }
  }
}
</style>
