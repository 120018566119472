import request from '../utils/request'
import commonMixins from './common'
import { clone } from 'ramda'
import { getAssignDate, setConvertValue } from '../utils/index'

export default {
  mixins: [commonMixins],
  methods: {
    // 默认值的监听
    initDefaultValueNotify() {
      // if (this.parent.disabled) { return }
      const { show, valueNotRelatedShow, defaultValueSource } = this.fieldJson

      // 关联获取 options
      if (!show && !valueNotRelatedShow) { return }
      if (![2, 3, 5, '2', '3', '5'].includes(defaultValueSource)) { return }

      if (defaultValueSource == 2) { // 关联默认值
        this.initDefaultValueNotifyNByRely()
      } else if (defaultValueSource == 3) { // 接口默认值
        this.initDefaultValueNotifyNByOrigin()
      } else if (defaultValueSource == 5) { // 高级配置 计算公式
        this.initDefaultValueNotifyNByAdvanced()
      }
    },

    // 通过关联字段获取默认值
    initDefaultValueNotifyNByRely() {
      const { defaultValueRelyGather } = this.fieldJson

      // 相关配置项为空则终止
      if (!defaultValueRelyGather || !defaultValueRelyGather.length) { return }

      // 条件分类
      const finalDefaultValueRelyGather = this.getFinalRelyGather(defaultValueRelyGather)

      // 初始化的是先计算一次默认值
      this.judgeRelyDefaultValue(finalDefaultValueRelyGather)

      // 添加监听关联字段变化
      this.formStore.subscribe((name) => {
        // 判断当前变动的字段是否是关联字段
        if (!this.hasRelyField(name, defaultValueRelyGather)) { return }

        this.judgeRelyDefaultValue(finalDefaultValueRelyGather)
      })
    },

    // 计算关联 默认值
    judgeRelyDefaultValue(relyGather = []) {
      // 获取匹配条件的关联值
      const judgeRelyRes = this.judgeRely(relyGather)

      // 非空判断
      // 因为返回值有可能是 0 或 空字符串这种，所以这里要判断是否等于 undefined
      if (judgeRelyRes !== undefined) {
        this.currentValue = judgeRelyRes
      }
    },

    // 通过接口获取默认值
    initDefaultValueNotifyNByOrigin() {
      const { defaultValueOriginUrl, defaultValueOriginParams = [] } = this.fieldJson

      // 相关配置项为空则终止
      if (!defaultValueOriginUrl) { return }

      const originParams = defaultValueOriginParams
      // 添加监听关联字段变化
      this.formStore.subscribe((name) => {
        // 判断当前变动的字段是否是关联字段
        if (!this.hasRelyParams(name, originParams)) { return }

        this.currentValue = ''

        // 获取配置的参数
        const params = this.getParams(originParams)
        // 所有参数都有值的情况下才会查询
        if (this.isAllParamsValued(params, originParams)) {
          this.getDefaultValue(params)
        }
      })
    },

    // 通过配置的计算公式计算
    initDefaultValueNotifyNByAdvanced() {
      const { defaultValueAdvancedGather } = this.fieldJson

      // 相关配置项为空则终止
      if (!defaultValueAdvancedGather || !defaultValueAdvancedGather.length) { return }

      // 初始化的是先计算一次默认值
      this.calculateDefaultValue(this.fieldJson)

      // 添加监听关联字段变化
      this.formStore.subscribe((name) => {
        // 判断当前变动的字段是否是关联字段
        if (!defaultValueAdvancedGather.some(item => {
          if (item.type == 5) { return false }

          // 判断是否有配置 extendKey
          // 如果配置则会被理解为数组项的 扩展值判断
          if (item.extendKey) {
            const isIncludes = this.isIncludes(name, item.path)
            return isIncludes && name.includes(item.extendKey)
          }

          if (!Array.isArray(name)) { return }
          return this.formStore.isSamePath(name, item.path)
        })) { return }

        this.calculateDefaultValue(this.fieldJson)
      })
    },

    // 计算默认值
    calculateDefaultValue(fieldJson) {
      const { defaultValueAdvancedGather } = fieldJson || {}
      if (!defaultValueAdvancedGather || !defaultValueAdvancedGather.length) { return }

      const res = defaultValueAdvancedGather.reduce((prev, item) => {
        const { path, type, value, extendKey, convert } = item

        // IDP-9112 特殊处理
        if (type == 5 && value && !path) {
          const { options = [] } = fieldJson

          this.currentValue = !options.length ? 0 : options.reduce((prev, item) => {
            if (prev == 'init') { return item.value }

            if (value == 'min') {
              return prev * 1 > item.value * 1 ? item.value : prev
            } else if (value == 'max') {
              return prev * 1 < item.value * 1 ? item.value : prev
            }

            return ''
          }, 'init')
          return
        }

        let pathValue = this.formStore.getFieldValue(path) || 0

        // type == 5 表示只需要计算最大或最小值
        if (pathValue === undefined || type == 5) { return 0 }

        const copyPath = clone(path)
        const pathKey = path[path.length - 1]
        const prevPath = copyPath.splice(0, copyPath.length - 1)
        const unitPath = [...prevPath, `${pathKey}Unit`]
        const pathValueUnit = this.formStore.getFieldValue(unitPath)

        // 这里需要转换一下
        const finalUnit = pathValueUnit == 1 ? 3 : pathValueUnit == 3 ? 1 : pathValueUnit
        const isDateUnit = !!finalUnit

        // 根据配置的逻辑来判断
        switch (type) {
          // +
          case 1:
            // TODO: 临时解决方案
            if (isDateUnit) {
              const currentValue = new Date(getAssignDate(pathValue, finalUnit, prev)) - 1000

              return currentValue
            } else if (extendKey) {
              if (!Array.isArray(pathValue)) { return prev }

              const prevValue = prev || 0
              const currentValue = pathValue.reduce((pr, i) => {
                let itemValue = i[extendKey] || 0
                // 是否需要对值进行转换
                if (convert) {
                  itemValue = setConvertValue(itemValue, convert)
                }

                return pr * 1 + (itemValue * 1)
              }, 0)

              return (prevValue * 1) + (currentValue * 1)
            } else {
              const prevValue = prev || 0
              const currentValue = pathValue || 0

              return (prevValue * 1) + (currentValue * 1)
            }
            // -
          case 2:
            return (prev - pathValue)
            // x
          case 3:
            return (prev * pathValue)
            // %
          case 4:
            return (prev / pathValue)
          default:
            if (extendKey) {
              if (Array.isArray(pathValue)) {
                pathValue = pathValue[this.arrayIndex][extendKey] || 0
              } else {
                pathValue = pathValue[extendKey] || 0
              }
            }
            // 是否需要对值进行转换
            if (convert) {
              pathValue = setConvertValue(pathValue, convert)
            }
            // 没有配置类型则直接返回当前值
            return pathValue || 0
        }
      }, 0)

      if (isNaN(res) || res == undefined) { return }

      this.currentValue = res

      if (['Date'].includes(this.data.renderType)) {
        this.isFocus = !!this.currentValue
      } else {
        this.isFocus = this.currentValue === 0 || !!this.currentValue
      }
    },

    async getDefaultValue(params = {}) {
      const { defaultValueOriginUrl, defaultValueOriginCfg, defaultValueResponseKey } = this.fieldJson
      const url = `/${defaultValueOriginUrl}`

      this.loading = true
      const res = await request({
        url,
        ...defaultValueOriginCfg,
        data: params
      })
      this.loading = false

      if (!res) { return }

      // 是否配置了指定字段
      // 如有配置则取制定字段的值
      if (defaultValueResponseKey) {
        const path = defaultValueResponseKey.split('.')
        // this.currentValue = res[defaultValueResponseKey]
        this.currentValue = this.getValueByPath(res, path)
      } else {
        this.currentValue = res
      }

      this.isFocus = !!this.currentValue
    },

    getValueByPath(obj, path = []) {
      return path.reduce((prev, item) => {
        if (typeof prev != 'object') { return prev }
        return prev[item]
      }, obj)
    },

    // 组合参数
    getParams(params) {
      const resParams = {}

      params.forEach(item => {
        if (item.source == 1) {
          const value = this.formStore.getFieldValue(item.value)
          const { expandKey } = item
          const finalValue = typeof value == 'object' && expandKey ? value[expandKey] : value

          this.setParamsItem(resParams, item.name, finalValue)
        } else if (item.source == 4) { // 扩展Key
          const value = this.parent?.extendDatas[item.name] || ''
          this.setParamsItem(resParams, item.name, value)
        } else if (item.source == 5) {
          Object.assign(resParams, this.value || {})
        } else {
          this.setParamsItem(resParams, item.name, item.value)
        }
      })

      return resParams
    },
    // 配置每项参数
    setParamsItem(params, key, value) {
      if (params[key]) {
        params[key] = Array.isArray(params[key]) ? [...params[key], value] : [params[key], value]
      } else {
        params[key] = value
      }
    },
    // 判断配置的关联数据是否有当前变动字段
    hasRelyParams(path, params) {
      if (!Array.isArray(path)) { return }
      return params.some(item => {
        return item.source == 1 && this.formStore.isSamePath(path, item.value)
      })
    },
    // 判断所有参数是否都是有效值
    isAllParamsValued(params, paramsConfig = []) {
      for (let i = 0; i < paramsConfig.length; i++) {
        const item = paramsConfig[i]
        const { required, name } = item

        // 必填
        if (required === true || required === undefined) {
          if (Array.isArray(params)) {
            if (!params[i] && params[i] !== 0) { return false }
          } else {
            if (!params[name] && name[name] !== 0) { return false }
          }
        }
      }

      return true
    },

    // 判断 arr1 是否包含 arr2
    isIncludes(arr1, arr2) {
      if (!arr1 || !arr2) { return }
      if (!Array.isArray(arr1) || !Array.isArray(arr2)) { return }

      return arr2.every((key, index) => {
        return key == arr1[index]
      })
    }
  }
}
