<template>
  <div
    v-if="ifRender"
    class="fu-min-form-item fu-min-form-ipt-item"
    :class="{edit: canEdit, focus: isV2 && isFocus}"
  >
    <!-- 分割组件 -->
    <FuDivider v-if="isV2 && fieldJson.divider" :type="fieldJson.dividerType || 'divider'" />

    <!-- V2 版本的标题 -->
    <p v-if="isV2 && label && placeholder" class="fu-form-v2-label">{{ label }}</p>
    <!-- 子标题 -->
    <p v-if="isV2 && aliasLabel" class="fu-min-form-aliaslabel">{{ aliasLabel }}</p>

    <Field
      ref="inputField"
      v-model="currentValue"
      v-inputScroll
      class="prefix-split"
      rows="1"
      autosize
      type="textarea"
      :label="isV2 ? (placeholder || label) : label"
      :placeholder="isV2 ? '' : placeholder"
      clearable
      :name="data.name + path"
      :rules="rules"
      :readonly="parent.isFormReadOnly && !canEdit"
      :disabled="finalDisabled"
      v-bind="fieldJson"
      @focus="onIptFocus"
      @blur="onIptBlur"
      @click="onClickField"
    >
      <!-- 自定义 label -->
      <template v-if="fieldJson.tip" slot="label">
        <FuFormLabel
          :parent="parent"
          :label="label"
          :field-json="fieldJson"
        />
      </template>

      <!-- 前缀 -->
      <!-- 新版 -->
      <template v-if="isV2 && hasPrefix" #left-icon>
        <div v-if="hasBuiltPrefix" class="fu-min-form-prefix">
          <VanImage
            v-if="fieldJson.prefixBuilt.includes('flag')"
            fit="cover"
            width="20"
            height="20"
            round
            :src="flagMap[getTenantConfig('region')]"
          />
          <span v-if="fieldJson.prefixBuilt.includes('country')">{{ getTenantConfig('region') }}</span>
          <span v-if="fieldJson.prefixBuilt.includes('code')">{{ getTenantConfig('prepend') }}</span>
          <span v-if="fieldJson.prefixBuilt.includes('currency')">{{ getTenantConfig('currency') }}</span>
        </div>
        <span v-else class="fu-min-form-prefix">{{ fieldJson.prefix || dynamicPrefix }}</span>
      </template>

      <!-- 旧版 -->
      <template v-if="(fieldJson.prefix || fieldJson.prefixPath) && !isV2" #button>
        <span v-if="fieldJson.prefix" class="fu-min-form-prefix">{{ fieldJson.prefix }}</span>
        <span v-if="fieldJson.prefixPath" class="fu-min-form-prefix">{{ dynamicPrefix }}</span>
      </template>

      <!-- 后缀 -->
      <template v-if="hasSuffix" #extra>
        <div v-if="hasBuiltSuffix" class="fu-min-form-suffix">
          <VanImage
            v-if="fieldJson.suffixBuilt.includes('flag')"
            fit="cover"
            width="20"
            height="20"
            round
            :src="flagMap[getTenantConfig('region')]"
          />
          <span v-if="fieldJson.suffixBuilt.includes('country')">{{ getTenantConfig('region') }}</span>
          <span v-if="fieldJson.suffixBuilt.includes('code')">{{ getTenantConfig('prepend') }}</span>
          <span v-if="fieldJson.suffixBuilt.includes('currency')">{{ getTenantConfig('currency') }}</span>
        </div>
        <span v-else class="fu-min-form-suffix">{{ fieldJson.suffix || dynamicSuffix }}</span>
      </template>
    </Field>

    <FuFormNote
      v-if="!parent.isFormReadOnly"
      :lang="parent.lang"
      :field-json="fieldJson"
    />
  </div>
</template>

<script>
import { Image as VanImage } from 'vant'
import commonMixins from '../mixins/materials'
import displayMixins from '../mixins/display'
import inputMixins from '../mixins/input'
import inputScroll from '../directive/inputScroll/index'
import { isArray, getTenantConfig } from '@fu/core'
import { getCDNhost } from '../utils'
const cdnHost = getCDNhost()

export default {
  name: 'FormItemInput',
  components: { VanImage },
  directives: { inputScroll },
  mixins: [commonMixins, displayMixins, inputMixins],
  data() {
    return {
      getTenantConfig,
      flagMap: {
        ID: `${cdnHost}/assets/country/ID.png`,
        TH: `${cdnHost}/assets/country/TH.png`,
        VN: `${cdnHost}/assets/country/VN.png`
      }
    }
  },
  computed: {
    hasBuiltPrefix() {
      const { prefixType, prefixBuilt } = this.fieldJson

      return prefixType == 3 && isArray(prefixBuilt) && !!prefixBuilt.length
    },
    hasPrefix() {
      const { prefixType, prefix } = this.fieldJson
      return this.hasBuiltPrefix || !!this.dynamicPrefix || (prefixType == 1 && !!prefix)
    },
    hasBuiltSuffix() {
      const { suffixType, suffixBuilt } = this.fieldJson

      return suffixType == 3 && isArray(suffixBuilt) && !!suffixBuilt.length
    },
    hasSuffix() {
      const { suffixType, suffix } = this.fieldJson
      return this.hasBuiltSuffix || !!this.dynamicSuffix || (suffixType == 1 && !!suffix)
    }
  }
}
</script>
