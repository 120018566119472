var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Form',{ref:"FuFormMin",staticClass:"fu-form-min fu-form-min-label-top",class:{
    'readonly': _vm.isFormReadOnly,
    'desktop': _vm.isDesktop,
    'V1': _vm.styleVersion != 'V2',
    'V2': _vm.styleVersion == 'V2',
    'split': _vm.isGroupSplit
  },on:{"failed":_vm.onFailed}},[(_vm.styleVersion == 'V2' && _vm.title)?_c('p',{staticClass:"fu-form-min-title"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),(_vm.isGroupSplit)?_vm._l((_vm.formItems),function(item,index){return _c('FormSplitItem',{key:index,class:_vm.splitValidate[item.key] === false ? 'error' : '',attrs:{"datas":_vm.formStore.values,"column":item},on:{"change":_vm.onSplitFormChange}})}):_vm._l((_vm.formItems),function(item,index){return _c(`FuMin${item.renderType}`,{key:index,tag:"components",attrs:{"data":item,"path":[]},model:{value:(_vm.value[item.prop]),callback:function ($$v) {_vm.$set(_vm.value, item.prop, $$v)},expression:"value[item.prop]"}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }