<template>
  <div v-if="ifRender" class="fu-min-form-item">
    <Field
      class="no-border fu-min-item-upload"
      :label="currentLabel"
      :name="data.name + path"
      :rules="rules"
      v-bind="fieldJson"
    >
      <!-- 自定义 label -->
      <template v-if="fieldJson.tip" slot="label">
        <FuFormLabel
          :parent="parent"
          :label="label"
          :field-json="fieldJson"
        />
      </template>

      <template #input>
        <!-- 示例图 -->
        <VanImage
          v-if="!parent.isFormReadOnly && fieldJson.sampleSrc"
          class="fu-min-form-upload-sample"
          lazy-load
          fit="contain"
          width="80"
          height="80"
          :src="fieldJson.sampleSrc"
          @click="ImagePreview({ images: [fieldJson.sampleSrc], closeable: true, showIndex: false })"
        />

        <!-- 上传组件 -->
        <!-- :max-size="fieldJson.maxFileSize || 1000000"           @oversize="handleOversize" -->
        <Uploader
          :ref="uploadRef"
          v-model="uploader"
          :max-count="fieldJson.maxCount || 1"
          :after-read="onAfterRead"
          :before-read="onBeforeRead"
          :preview-full-image="!isVideo && !isV2"
          :preview-options="{
            closeable: true
          }"
          :accept="accept || (isVideo ? 'video/*' : 'image/*')"
          v-bind="h5UploaderProps"
        >
          <template #preview-cover="item">
            <VanImage
              fit="cover"
              width="100%"
              height="100%"
              :src="item.url"
              @click="onClickPreview(item)"
            />
          </template>

          <span class="none" />
        </Uploader>

        <!-- APP 内展示按钮 -->
        <a
          v-if="!isMaxCount && !parent.isFormReadOnly"
          class="fu-form-upload-btn"
          href="javascript:"
          @click="onClickUpload"
        >
          <Icon
            :name="isVideo ? 'video' : 'photograph'"
            color="#CBCBCB"
            size="30"
          />
        </a>
      </template>
    </Field>

    <FuFormNote
      v-if="!parent.isFormReadOnly"
      :lang="parent.lang"
      :field-json="fieldJson"
    />

    <!-- 视频预览弹窗 -->
    <Overlay class="preview-overlay" :show="videoPreviewOverlay">
      <Icon
        class="overlay-close-btn"
        name="clear"
        size="20"
        color="#c8c9cc"
        @click="videoPreviewOverlay = false"
      />
      <video
        width="100%"
        height="60%"
        type="video/mp4"
        :src="currentVideoPreviewUrl"
        muted="muted"
        preload="preload"
        autoplay
        controls="controls"
        controlslist="nodownload"
        disablePictureInPicture="true"
      />
    </Overlay>

    <!-- 文件触发选项 -->
    <Popup
      v-model="isShowPopup"
      position="bottom"
      round
      :style="{ height: '160px' }"
    >
      <ul class="fu-upload-handle">
        <li
          v-for="(item, index) in uploadHandleLs"
          :key="index"
          :class="{'choose-item': true}"
          @click="onUploadHandleClick(item)"
        >
          <Icon
            :name="item.icon || 'photo'"
            color="#CBCBCB"
            size="50"
          />
          <span class="text">{{ item.name }}</span>
        </li>
      </ul>
    </Popup>
  </div>
</template>

<script>
import Vue from 'vue'
import { Lazyload, ImagePreview, Overlay, Notify, Popup } from 'vant'
Vue.use(Notify).use(Lazyload).use(ImagePreview)

import commonMixins from '../mixins/materials'
import displayMixins from '../mixins/display'
import h5Upload from '../mixins/upload'
import { clone } from 'ramda'
import Dao from '@/utils/dao'
import { Uploader, Icon, Image as VanImage } from 'vant'
import request from '../utils/request'
import axios from 'axios'
import { dispatchAction, isInApp } from '@/utils/dispatch'
import { Session } from '@/utils/storage'
import { triggerAttachmentTask } from '@/api'
import { formatDateId } from '@/filters'
import { getVideoPlayerInfo } from '../utils'

export default {
  name: 'FormItemSwitch',
  components: { Uploader, Icon, VanImage, Overlay, Popup },
  mixins: [commonMixins, displayMixins, h5Upload],
  data() {
    return {
      loading: false,
      videoPreviewOverlay: false,
      uploader: [],
      attachmentMetaPath: [],
      attachmentMeta: [],
      isInApp,
      ImagePreview,
      currentVideoPreviewUrl: '',

      defaultThumbnailUrl: 'https://static.fuse.com.vn/fu/imgs/MP4.png',
      defaultThumbnailMap: {
        video: 'https://static.fuse.com.vn/fu/imgs/MP4.png',
        pdf: 'https://static.fuse.com.vn/fu/imgs/PDF.png',
        xlsx: 'https://static.fuse.com.vn/fu/imgs/XLSX.png',
        doc: 'https://static.fuse.com.vn/fu/imgs/DOC.png',
        ppt: 'https://static.fuse.com.vn/fu/imgs/PPTX.png',
        file: 'https://static.fuse.com.vn/fu/imgs/FILE.png'
      },

      // 标准文件类型
      fileTypeMap: {
        img: ['.jpeg', '.jpg', '.bmp', '.gif', '.png', '.tiff', '.raw', '.svg'],
        video: ['.mp4', '.mov', '.rmvb', '.avi'],
        pdf: ['.pdf'],
        xlsx: ['.xlsx', '.xls'],
        doc: ['.docx', '.doc'],
        ppt: ['.ppt', '.pptx']
      },

      readResult: true
    }
  },
  computed: {
    currentLabel() {
      const { attachmentTypeLabel, attachmentTypeLabelLocal, labelSameAsTypeLabel } = this.fieldJson

      if (labelSameAsTypeLabel) {
        return this.parent.lang == 'en' ? attachmentTypeLabel : attachmentTypeLabelLocal
      } else {
        return this.label
      }
    },
    uploadRef() {
      return `${this.currentLabel}Uploader`
    },
    baseApi() {
      const isId = window.location.href.indexOf('f4/boss') > -1
      return isId ? 'f4/api' : ''
    },
    isMaxCount() {
      const maxCount = this.fieldJson.maxCount || 1

      return this.uploader.length >= maxCount
    },
    isVideo() {
      if (Array.isArray(this.fieldJson.uploadType)) {
        return this.fieldJson.uploadType.includes('video')
      }
      // 兼容旧配置
      return [2].includes(this.fieldJson.uploadType)
    },
    isV2() {
      // 默认走V2版本的逻辑
      return Array.isArray(this.fieldJson.uploadType) && !!this.fieldJson.uploadType.length
      // return !this.fieldJson.uploadType || Array.isArray(this.fieldJson.uploadType)
    }

  },
  watch: {
    uploader: {
      handler() {
        this.setCurrentValue()
      }
    }
  },
  created() {},
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const copyPath = clone(this.currentPath)
      const prevPath = copyPath.splice(0, copyPath.length - 1)

      const typePath = [...prevPath, 'attachmentType']
      const labelPath = [...prevPath, 'attachmentTypeLabel']
      const labelLocalPath = [...prevPath, 'attachmentTypeLabelLocal']
      this.attachmentMetaPath = [...prevPath, 'attachmentMeta']

      this.formStore.setFieldValue(typePath, this.fieldJson.attachmentType)
      this.formStore.setFieldValue(labelPath, this.fieldJson.attachmentTypeLabel)
      this.formStore.setFieldValue(labelLocalPath, this.fieldJson.attachmentTypeLabelLocal)
    },

    // 更新表的值
    setCurrentValue() {
      const value = this.uploader.filter(item => {
        return item.status == 'done'
      }).map(item => {
        return item.fileKey || item.message || item.url
      })
      const attachmentMeta = this.uploader.filter(item => {
        return item.status == 'done'
      }).map(item => {
        return {
          attachmentPath: item.fileKey,
          attachmentFileType: this.isVideo ? 'video' : 'image',
          metadata: {
            ...item.attachmentMeta || {}
          }
        }
      })

      this.formStore.setFieldValue(this.currentPath, value)
      this.formStore.setFieldValue(this.attachmentMetaPath, attachmentMeta)
    },

    // 点击上传按钮
    onClickUpload() {
      console.log('isV2', this.isV2)
      console.log('isVideo', this.isVideo)
      console.log('isMaxCount', this.isMaxCount)
      if (this.isMaxCount) { return }

      if (this.isInApp) {
        if (this.loading) { return }
        this.loading = true
        // 添加loading
        // this.uploader.push({ status: 'uploading' })

        // 特殊状态 实现签名上传
        // 特殊的上传通道
        if (['jumpToSignPage'].includes(this.fieldJson.attachmentType)) {
          dispatchAction({
            method: this.fieldJson.attachmentType,
            params: {},
            callback: this.onGetPhoto
          })

          return
        }

        // 新版 V2 逻辑
        if (this.isV2) {
          // maxSize => 上传文件的最大值
          // maxDuration => 上传为视频的时候，可上传的最大时长
          // uploadSource => 上传文件的来源 1: 仅支持拍摄上传  2: 仅支持相册上传  3: 两者都支持
          // uploadType => 支持上传的文件类型  默认为图片
          const { maxSize = 100 * 1024, maxDuration = 2 * 60, uploadSource = 3, uploadType = ['img'] } = this.fieldJson

          const fileType = uploadType.map(type => `.${type}`)
          const fileFinalType = uploadType.map(type => {
            return this.fileTypeMap[type] || `.${type}`
          }).flat()

          // 视频通信
          dispatchAction({
            method: 'uploadDocument',
            params: {
              maxSize: maxSize + '', // 可上传文件大小的最大值  单位是 KB
              maxDuration: maxDuration + '', // 视频最大时长 单位时 s
              fileType, // 支持的文件类型
              fileFinalType, // 支持文件的类型的最终值 （标准类型）
              mode: uploadSource // 文件的来源  1: 仅支持拍摄上传  2: 仅支持相册上传  3: 两者都支持
            },
            callback: this.onGetVideo
          })

          return
        }

        // 以下是旧版逻辑
        if (this.isVideo) {
          const { maxSize = 100 * 1024, maxDuration = 2 * 60, uploadSource = 3 } = this.fieldJson
          console.log('dispatchAction getVideo')
          // 视频通信
          dispatchAction({
            method: 'getVideo',
            params: {
              maxSize: maxSize + '', // 视频大小的最大值  单位是 KB
              maxDuration: maxDuration + '', // 视频最大时长 单位时 s
              mode: uploadSource // 视频的来源  1: 仅支持拍摄上传  2: 仅支持相册上传  3: 两者都支持
            },
            callback: this.onGetVideo
          })
        } else {
          // 图片上传
          dispatchAction({
            method: 'getPhoto',
            params: {
              mode: this.fieldJson.uploadSource
            },
            callback: this.onGetPhoto
          })
        }
      } else {
        this.uploadInH5()
      }
    },

    // 上传视频
    // 与app的通信回调
    async onGetVideo(file) {
      if (!file) {
        this.delUploadingFile()
        return
      }

      // 状态吗判断
      // code == 100000 取消上传
      // code == 100001 文件提取失败
      // code == 100002 文件大小限制
      // code == 100003 压缩失败
      // code == 100004 上传失败
      // code == 100005 选择文件类型错误
      if (file && file.code && file.code != 200) {
        // 取消上传不需要提醒
        if (file.code && file.code != 100000) {
          Notify({ type: 'warning', message: this.$t(`dynamicForm.uploadFail${file.code}`) })
        }
        this.delUploadingFile()
        return
      }

      // 取消选择
      if (!file.fileKey && !file.videoKey) {
        this.delUploadingFile()
        return
      }

      const { name, fileUrl, fileKey, videoKey, videoUrl, attachmentMeta = {}} = file

      // 只有视频才会获取缩略图
      const triggerAttachmentConfig = this.isVideo ? await this.triggerAttachmentTask({
        attachmentPath: videoKey || fileKey,
        attachmentType: 'video',
        extensionDataJson: JSON.stringify({
          createVideoSnapshot: true
        })
      }) || {} : {}

      const fileExtension = this.getFileExtension(fileUrl || videoUrl)

      const fileItem = {
        status: 'uploading',
        name: name,
        isVideo: this.isVideo,

        url: this.getDefaultThumbnailUrl(fileExtension) || triggerAttachmentConfig.thumbnailUrl || fileUrl,
        fileKey: fileKey || videoKey,
        fileUrl: fileUrl || videoUrl,
        thumbnailUrl: triggerAttachmentConfig.thumbnailUrl,
        thumbnailKey: triggerAttachmentConfig.thumbnailPath,
        attachmentMeta: {
          ...attachmentMeta,
          ...triggerAttachmentConfig
        }
      }

      // 判断是否需要添加水印
      const { hasWatermark } = this.fieldJson

      // 无需添加水印则直接更新
      if (!hasWatermark) {
        this.delUploadingFile()

        this.uploader.push(fileItem)
        this.onAfterRead(fileItem)

        return
      }

      // 以下为添加水印的逻辑
      const watermarkRes = triggerAttachmentConfig.targetAttachmentPath ? triggerAttachmentConfig : await this.triggerAttachmentTask({
        attachmentPath: videoKey,
        attachmentType: 'video'
      })

      if (!watermarkRes || !watermarkRes.targetAttachmentPath) {
        Notify({ type: 'warning', message: 'Failed to add watermark' })
        this.delUploadingFile()
        return
      }

      this.delUploadingFile()
      const finalFileItem = {
        ...fileItem,
        fileKey: watermarkRes.targetAttachmentPath
      }
      this.uploader.push(finalFileItem)
      this.onAfterRead(finalFileItem)
    },
    // 与app的通信回调
    // 或 h5 的上传回调
    onGetPhoto(file) {
      // 取消选择
      if (!file || (!file.file && !file.fileUrl)) {
        this.delUploadingFile()
        return
      }

      let dataURL = null
      if (file.file) {
        const base64 = this.getBase64(file.file)
        dataURL = `data:image/${file.type};base64,${base64}`
      } else {
        dataURL = file.fileUrl
      }

      const fileItem = {
        status: 'uploading',
        name: file.name,
        url: dataURL,
        isImage: true,
        fileKey: file.fileKey || '',
        fileUrl: dataURL
      }

      this.delUploadingFile()

      this.uploader.push(fileItem)
      this.onAfterRead(fileItem)
    },

    // 附件处理
    async triggerAttachmentTask(params = {}) {
      const { watermarkContent } = this.fieldJson
      const taskParams = {}

      if (!watermarkContent || watermarkContent == 'currentTime') {
        taskParams.taskData = formatDateId(new Date().getTime(), 'DD/MM/YYYY HH:mm:ss')
      }
      return triggerAttachmentTask({
        ...params,
        taskTypeCode: 'watermark_text', // 做水印
        taskParams
      })
    },
    // 删除 uploading 占位文件
    delUploadingFile() {
      this.loading = false
      const lastFile = this.uploader[this.uploader.length - 1] || {}
      // 如果最后一项是 loading 占位文件则删除
      if (lastFile.status == 'uploading' && !lastFile.fileKey) {
        this.uploader.pop()
      }
    },

    async onBeforeRead(file) {
      this.readResult = false
      // 视频限制时长
      if (this.isVideo) {
        const videoInfo = await getVideoPlayerInfo(file) || {}

        const { maxDuration = 2 * 60 } = this.fieldJson

        if (videoInfo.duration > maxDuration) {
          Notify({ type: 'warning', message: this.$t('dynamicForm.uploadFail100006', { maxDuration }) })
          return false
        }
      }
      this.readResult = true
    },

    // 选取文件后的回调
    async onAfterRead(file) {
      if (this.fieldJson.uploadTarget == 'oss') {
        // h5的直接上传
        if (!this.isInApp) {
          if (!this.readResult) {
            this.uploader = this.uploader.filter(item => item.status)
            return
          }

          const res = await this.uploadToOss(file)
          if (!res || res.result !== 'ok') { return }

          const { fileUrl, fileKey, videoKey } = res

          file.fileKey = fileKey
          file.fileUrl = fileUrl

          const fileExtension = this.getFileExtension(res.fileUrl)
          // 只有视频才会获取缩略图
          const triggerAttachmentConfig = this.isVideo ? await this.triggerAttachmentTask({
            attachmentPath: videoKey || fileKey,
            attachmentType: 'video',
            extensionDataJson: JSON.stringify({
              createVideoSnapshot: true
            })
          }) || {} : {}
          file.url = this.getDefaultThumbnailUrl(fileExtension) || triggerAttachmentConfig.thumbnailUrl || res.fileUrl

          file.attachmentMeta = triggerAttachmentConfig
        }

        file.status = file.fileKey ? 'done' : 'failed'
        if (file.fileKey) {
          // 更新值
          this.setCurrentValue()
        }
      } else {
        this.uploadToServer(file)
      }
    },
    handleOversize(file) {
      // 文件大小超过限制 会触发这个回调
      console.log('👨‍🎨🎨🍑 handleOversize', file)
    },
    // 传统--通过后台接口上传到自己的服务器
    async uploadToServer(file) {
      const url = `${window.location.origin}/insurance-finance-vs-api/api/fuse/file/oper/file/upload`
      const formData = new FormData()
      formData.append('files', file.file)

      const res = await request({
        url: url,
        method: 'post',
        data: formData
      })

      if (res && res.affixId) {
        file.url = res.imgPath
        file.status = 'done'
      } else { // 上传失败
        file.status = 'failed'
      }

      // 更新值
      this.setCurrentValue()
    },

    // oss--上传到阿里云
    async uploadToOss(file) {
      file.status = 'loading'
      const params = {
        fileNames: [file.name || file.file.name]
      }

      const userInfo = Session.get('appInfo') || Dao.get('userInfo') || {}

      // 原本 ${this.baseApi}/pro/document/store/upload/getUrl
      let url
      let token
      if (window.location.href.includes('/claim') || window.location.href.includes('/temp')) {
        token = Dao.get('tempToken')
        url = `${this.baseApi}/claim/temp/store/upload/getUrl`
      } else {
        token = Dao.get('inspectionToken')
        url = `${this.baseApi}/local/inspection/store/upload/getUrl`
      }
      const res = await request({
        url: url,
        method: 'post',
        data: params,
        headers: {
          appCode: 'IDP_BOSS',
          fusetoken: userInfo.token,
          'x-5a-temp-token': token ?? ''
        }
      })

      // 接口异常则终止
      if (!res) { return {} }

      // 上传阿里云
      const ossBackData = res[0]

      const fileBuffer = await this.getArrayBuffer(file.file)

      const putRes = await this.put(ossBackData.uploadUrl, fileBuffer, ossBackData.headers)

      if (putRes.status == 200) {
        return { result: 'ok', fileKey: ossBackData.fileKey, fileUrl: ossBackData.fileUrl }
      } else {
        return {}
      }
    },
    // ArrayBuffer 转 Base64
    getBase64(file) {
      return window.btoa(new Uint8Array(file).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        '')
      )
    },
    // File 转 ArrayBuffer
    getArrayBuffer(file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        let imgResult = ''
        reader.readAsArrayBuffer(file)
        reader.onload = function () {
          imgResult = reader.result
        }
        reader.onerror = function (error) {
          reject(error)
        }
        reader.onloadend = function () {
          resolve(imgResult)
        }
      })
    },

    // base64 转 File
    base64ConvertFile(dataUrl, fileName) {
      if (typeof dataUrl != 'string') {
        this.$toast('dataUrl 不是字符串')
        return
      }

      const arr = dataUrl.split(';base64,') // [data:image/png,xxx]
      const mime = arr[0].replace('data:', '') // mime后端识别用的文件格式数据
      // const fileType = mime.split('/').pop() // 获取文件格式
      const bstr = atob(arr[1]) // base64解码
      // 创建Unicode编码的数组对象，每个值都是Unicode
      const u8arr = new Uint8Array(bstr.split('').map(str => str.charCodeAt(0)))

      const blob = new Blob([u8arr], { type: mime })

      blob.lastModifiedDate = new Date()
      blob.name = fileName

      return blob
    },

    put(url, data, headers) {
      return new Promise((resolve, reject) => {
        axios.put(url, data, { headers: headers }).then(response => (
          resolve(response)
        )).catch(err => {
          reject(err.data)
        })
      })
    },

    onClickPreview(file) {
      if (file.status == 'loading') { return }

      const fileExtension = this.getFileExtension(file.fileUrl)

      const isImg = this.fileTypeMap['img'].includes(fileExtension)
      const isVideo = this.fileTypeMap['video'].includes(fileExtension)

      // 目前只支持 图片和 视频的 预览
      if (!isImg && !isVideo) {
        Notify({ type: 'warning', message: this.$t('dynamicForm.uploadInfo100000') })
        return
      }

      // 视频
      if (isVideo) {
        this.currentVideoPreviewUrl = file.fileUrl
        this.videoPreviewOverlay = true
      } else if (isImg) { // 图片
        ImagePreview([file.fileUrl])
      } else {
        console.log('未知预览类型，预览失败')
      }
    },
    // 获取文件类型
    getFileType(fileUrl) {
      const fileExtension = this.getFileExtension(fileUrl)
      const fileExs = [
        ...this.fileTypeMap['pdf'],
        ...this.fileTypeMap['xlsx'],
        ...this.fileTypeMap['doc'],
        ...this.fileTypeMap['ppt']
      ]
      return {
        isImg: this.fileTypeMap['img'].includes(fileExtension),
        isVideo: this.fileTypeMap['video'].includes(fileExtension),
        isFile: fileExs.includes(fileExtension)
      }
    },

    // 根据文件路径获取 扩展名
    getFileExtension(url, abbreviation = false) {
      const urlObj = new URL(url)
      const pathname = urlObj.pathname // 获取路径部分

      // 从路径中获取文件名
      const fileName = pathname.substring(pathname.lastIndexOf('/') + 1)

      // 获取文件扩展名
      const fileExtension = fileName.split('.').pop()

      if (!abbreviation) {
        return `.${fileExtension}`
      } else {
        const type = Object.keys(this.fileTypeMap).filter(key => {
          return this.fileTypeMap[key].includes(fileExtension.toLowerCase())
        })

        return type[0] || `.${fileExtension}`
      }
    },

    // 通过文件扩展名 获取默认图
    getDefaultThumbnailUrl(fileExtension) {
      for (const key in this.fileTypeMap) {
        const types = this.fileTypeMap[key]

        if (types.includes(fileExtension.toLowerCase())) {
          // 图片和视频都不需要使用默认图来设置封面
          if (key == 'img' || key == 'video') { return null }

          return this.defaultThumbnailMap[key] || this.defaultThumbnailMap.file
        }
      }

      return this.defaultThumbnailMap.file
    }
  }
}
</script>

<style lang="scss" scoped>

.preview-overlay {
  display: flex;
  align-items: center;
}
.overlay-close-btn {
  position: absolute;
  top: .2rem;
  right: .2rem;
}

.fu-form-upload-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 80PX;
  height: 80PX;
  border: 2px dashed #ebedf0;
  box-sizing: border-box;
}

.none {
  display: none;
}

.fu-upload-handle {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fu-upload-handle {
  .choose-item {
    width: 30%;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>

