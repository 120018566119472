// import { propEq, find } from 'ramda'

export default {
  data() {
    return {
      dynamicPrefix: '',
      dynamicSuffix: ''
    }
  },
  created() {
    this.initDynamicPrefixNotify()
    this.initDynamicSuffixNotify()
  },
  methods: {
    // 动态 prefix 配置
    initDynamicPrefixNotify() {
      if (!this.fieldJson.prefixType || !this.fieldJson.prefixPath) { return }
      if (this.fieldJson.prefixType != '2') { return }
      if (!this.fieldJson.prefixPath.length) { return }

      const { prefixPath } = this.fieldJson

      const associatedValue = this.formStore.getFieldValue(prefixPath)
      this.dynamicPrefix = associatedValue

      // 添加监听关联字段变化
      this.formStore.subscribe((name) => {
        if (!Array.isArray(name)) { return }
        // 判断当前变动的字段是否是关联字段
        if (!this.formStore.isSamePath(name, prefixPath)) { return }

        const associatedValue = this.formStore.getFieldValue(prefixPath)

        this.dynamicPrefix = associatedValue
      })
    },
    // 动态 prefix 配置
    initDynamicSuffixNotify() {
      if (!this.fieldJson.suffixType || !this.fieldJson.suffixPath) { return }
      if (this.fieldJson.suffixType != '2') { return }
      if (!this.fieldJson.suffixPath.length) { return }

      const { suffixPath } = this.fieldJson

      const associatedValue = this.formStore.getFieldValue(suffixPath)
      this.dynamicSuffix = associatedValue

      // 添加监听关联字段变化
      this.formStore.subscribe((name) => {
        if (!Array.isArray(name)) { return }
        // 判断当前变动的字段是否是关联字段
        if (!this.formStore.isSamePath(name, suffixPath)) { return }

        const associatedValue = this.formStore.getFieldValue(suffixPath)

        this.dynamicSuffix = associatedValue
      })
    }
  }
}
