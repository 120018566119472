<template>
  <div class="fu-form-min-split-item">
    <Alert v-if="notGroup" :data="column" />

    <div v-else class="fu-form-min-split-group">
      <div class="left">
        <p class="title">{{ column.name }}</p>
        <p class="desc">{{ column.aliasName }}</p>
      </div>
      <div class="right" @click="onClickInput">{{ $t('input') }}</div>
    </div>

    <transition name="box">
      <div v-if="isShowGroupForm" class="fu-form-group-page container fixed-header fixed-footer v2">
        <FuNav :title="$t('submitDatas')" :back="onGroupBack" />

        <FuFormMin
          ref="FuFormMinSplitForm"
          v-model="groupDatas"
          :schema="groupSchema"
          :lang="parent.lang"
          :root-schema="parent.schema"
        />

        <FuFooter
          class="v2"
          :name="$t('save')"
          color="#E74039"
          @click="onSave"
        />

        <!-- 特殊校验弹窗 -->
        <FuPopup
          v-model="isShowSpecialDialog"
          :close-on-click-overlay="false"
          :show-icon="false"
          :closeable="true"
          :title="$t('ageDataDiffers')"
          :desc="$t('ageDateDiffersMessage')"
          :footer="true"
          cancel-class="block"
          confirm-class="block info"
          :confirm-text="$t('close')"
          :cancel-text="$t('changeKTPNumber')"
          :on-cancel="onChangeKTP"
          :on-confirm="onClose"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import Alert from '../materials/alert.vue'
import FuNav from '@/components/v2/FuNav'
import FuFooter from '@/components/FuFooter'
import FuPopup from '@/components/v2/FuPopup/index.vue'
import { isArray } from '@fu/core'
import { setConvertValue } from '../utils/index'

export default {
  name: 'FuFormMinSplitItem',
  components: {
    FuFormMin: () => import('../index.vue'),
    Alert, FuNav, FuFooter, FuPopup
  },
  props: {
    datas: { type: Object, default: () => {} },
    column: { type: Object, default: () => {} }
  },
  data() {
    return {
      isShowGroupForm: false,
      isShowSpecialDialog: false
    }
  },
  computed: {
    parent() {
      let parent = this.$parent

      if (!parent) {
        return parent
      }
      let parentName = parent.$options.name

      while (parentName !== 'FuFormMin') {
        parent = parent.$parent
        parentName = parent.$options.name
      }
      return parent
    },
    notGroup() {
      return !['Object', 'Array'].includes(this.column.renderType)
    },
    isCustomer() {
      return !!this.$route.query['X-5a-temp-token']
    }
  },
  methods: {
    onClickInput() {
      this.groupDatas = this.datas

      const parentSchema = JSON.parse(JSON.stringify(this.parent.schema))

      this.groupSchema = {
        ...parentSchema,
        fieldJson: {
          ...parentSchema.fieldJson,
          groupDisplayMode: ''
        },
        columns: [this.column]
      }

      this.isShowGroupForm = true
    },

    onGroupBack() {
      this.isShowGroupForm = false
    },
    async onSave() {
      this.$refs.FuFormMinSplitForm.validate().then(res => {
        // FIXME: 非常特殊的一段业务判断
        // FIXME: 目前只有 Life 险，且动态表单配置分块展示的时候才有
        // FIXME: 且只有印尼租户
        const specialRes = this.specialValidate()

        if (!specialRes) {
          this.isShowSpecialDialog = true
          return
        }

        this.updateDats(true)
      }).catch(err => {
        this.updateDats(false)
        console.log(err)
      })
    },

    updateDats(validate) {
      this.$emit('change', {
        key: this.column.key,
        datas: this.groupDatas,
        validate
      })
      this.onGroupBack()
    },

    // 通过输入的 KTP 进行 出生年月和 性别的判断
    // Example: KTP = 1234560101019999
    // The first 6 digits are free (no validation in our system). Example: 123456
    // The second 6 digits determine the "Gender" and "DOB." Example: 010101
    // For DOB: 010101 corresponds to "01-January-2001."
    // For Gender male: 010101 corresponds to "01-January-2001" & "Gender = Male."
    // For Gender female : 410101, it corresponds to "01-January-2001" & "Gender = Female" (Female is determined by date of DOB + 40,  (so 41 = 40 + 01).
    specialValidate() {
      let currentData = this.groupDatas[this.column.key] || {}

      if (isArray(currentData)) {
        currentData = currentData[0] || {}
      }

      const { gender, age, birthday, identityNo } = currentData
      const identityStr = identityNo + ''

      // 没有值或值不规范的时候不校验
      if (!age || !birthday || !identityStr || identityStr.length !== 16) { return true }

      const birthDatePart = identityStr.substring(6, 12)

      const dayPart = birthDatePart.substring(0, 2)
      const ktpGender = dayPart > 40 ? 2 : 1

      const day = dayPart > 40 ? dayPart - 40 : dayPart
      const month = birthDatePart.substring(2, 4)
      const yearShort = birthDatePart.substring(4, 6)
      const year = yearShort < 30 ? `20${yearShort}` : `19${yearShort}`

      const birthDate = `${year}-${month}-${day}`
      const ktpAge = setConvertValue(new Date(birthDate).getTime(), 'age')
      const birthdayMonth = new Date(birthday).getMonth() + 1
      const birthdayDay = new Date(birthday).getDate()

      // 性别或年龄不匹配则返回false
      if (ktpGender != gender || ktpAge != age || birthdayMonth != month * 1 || birthdayDay != day * 1) {
        return false
      }

      return true
    },

    onChangeKTP() {
      // TODO: 页面滚动到 ktp
    },
    onClose() {
      if (this.isCustomer) {
        this.isShowSpecialDialog = false
      }
    }
  }
}
</script>

<style lang="scss">
.fu-form-min-split-item.error .fu-form-min-split-group,
.fu-form-min-split-group.error {
  border-color: #E74039;
}

.fu-form-min-split-group {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px;
  background-color: #fff;
  border: 1px solid  #F5F5F5;
  border-radius: 8px;
  box-shadow: 0px 4px 20px 0px #0000001A;

  .left {}
  .right {
    flex-shrink: 0;
    box-sizing: border-box;
    width: 66px;
    height: 28px;
    line-height: 28px;
    padding: 0 12px;
    border-radius: 8px ;
    border: 1px solid #D3D3D3;
    background-color: #F5F5F5;
    text-align: center;
  }

  .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: #000;
  }

  .desc {
    font-size: 12px;
    line-height: 16px;
    color: #000;
  }
}

.fu-form-min-split-item + .fu-form-min-split-item {
  margin-top: 16px;
}

.fu-form-group-page {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  // padding-left: 10px;
  // padding-right: 10px;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  overflow-y: auto;
}
</style>
