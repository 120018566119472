import commonMixins from './common'

export default {
  mixins: [commonMixins],
  data() {
    return {
      isDisabled: false
    }
  },
  created() {
    this.initDisabledNotify()
  },
  computed: {
    finalDisabled() {
      return !!this.fieldJson.disabled || this.isDisabled
    }
  },
  methods: {
    initDisabledNotify() {
      if (this.fieldJson.disabled) { return }
      if (!this.fieldJson.disabledRely) { return }
      if (!this.fieldJson.disabledRelyGather || !this.fieldJson.disabledRelyGather.length) { return }

      const disabledRelyGather = this.fieldJson.disabledRelyGather

      // 初始化的时候先计算一次是否显示
      this.isDisabled = this.judgeDisabled(disabledRelyGather)

      // 添加监听关联字段变化
      this.formStore.subscribe((name) => {
        // 判断当前变动的字段是否是关联字段
        if (!this.hasRelyField(name, disabledRelyGather)) { return }

        // 最终的判断结果直接决定是否显示
        this.isDisabled = this.judgeDisabled(disabledRelyGather)
        // this.$set(this, 'isDisabled', this.judgeDisabled(disabledRelyGather))
      })
    },

    judgeDisabled(relyGather) {
      let relyRes = true

      for (let i = 0; i < relyGather.length; i++) {
        const item = relyGather[i]

        // 如果是 或 关系，则判断之前的结果是不是 true
        // 如果是 true 就直接返回 true
        // 如果是 false 就继续判断
        if (item.type == 'or') {
          if (relyRes) {
            return relyRes
          } else {
            relyRes = this.judgeByConditions(item.list)
          }
        } else {
          // 如果是 且 的关系，就判断之前的结果
          // 如果是 true 就继续往下判断
          // 如果是 false 就直接终止
          if (relyRes) {
            relyRes = this.judgeByConditions(item.list)
          } else {
            return relyRes
          }
        }
      }

      return relyRes
    }
  }
}
