<template>
  <div
    v-if="ifRender"
    class="fu-min-form-item fu-min-form-ipt-item"
    :class="{edit: canEdit, focus: isV2 && isFocus}"
  >

    <!-- 分割组件 -->
    <FuDivider v-if="isV2 && fieldJson.divider" :type="fieldJson.dividerType || 'divider'" />

    <!-- 标题 -->
    <p v-if="isV2 && label && placeholder" class="fu-form-v2-label">{{ label }}</p>
    <!-- 子标题 -->
    <p v-if="isV2 && aliasLabel" class="fu-min-form-aliaslabel">{{ aliasLabel }}</p>

    <!-- 展示字段 -->
    <Field
      ref="inputField"
      v-model="numericDisplayValue"
      v-inputScroll
      :label="isV2 ? (placeholder || label) : label"
      :placeholder="isV2 ? '' : placeholder"
      :type="fieldJson.valueType || 'number'"
      clearable
      :name="data.name + path"
      :readonly="parent.isFormReadOnly && !canEdit"
      :rules="fieldJson.filter ? [] :rules"
      :class="fieldJson.filter ? 'fu-min-form-numeric-normal' : ''"
      :disabled="finalDisabled"
      v-bind="fieldJson"
      @blur="onBlur"
      @focus="onIptFocus"
      @click="onClickField"
    >
      <!-- 自定义 label -->
      <template v-if="fieldJson.tip" slot="label">
        <FuFormLabel
          :parent="parent"
          :label="label"
          :field-json="fieldJson"
        />
      </template>

      <!-- 前缀 -->
      <!-- 新版 -->
      <template v-if="isV2 && hasPrefix" #left-icon>
        <div v-if="hasBuiltPrefix" class="fu-min-form-prefix">
          <VanImage
            v-if="fieldJson.prefixBuilt.includes('flag')"
            fit="cover"
            width="20"
            height="20"
            round
            :src="flagMap[getTenantConfig('region')]"
          />
          <span v-if="fieldJson.prefixBuilt.includes('country')">{{ getTenantConfig('region') }}</span>
          <span v-if="fieldJson.prefixBuilt.includes('code')">{{ getTenantConfig('prepend') }}</span>
          <span v-if="fieldJson.prefixBuilt.includes('currency')">{{ getTenantConfig('currency') }}</span>
        </div>
        <span v-else class="fu-min-form-prefix">{{ fieldJson.prefix || dynamicPrefix }}</span>
      </template>

      <!-- RP-4657
    https://rd-wiki.fuseinsurtech.com/display/product/VN-Product+Factory+Improvement -->
      <template v-if="fieldJson.prefix ? (isV2 ? isFocus : false) : false" #button>
        <span>{{ fieldJson.prefix }}</span>
      </template>
      <template v-if="fieldJson.suffix ? (isV2 ? isFocus : false) : false" #extra>
        <span>{{ fieldJson.suffix }}</span>
      </template>
    </Field>

    <!-- 添加一个隐藏的字段用来做校验 -->
    <Field
      v-if="ifRender && fieldJson.filter"
      v-model="currentValue"
      class="fu-min-form-numeric-disabled"
      :placeholder="placeholder"
      type="number"
      clearable
      :name="`${data.name}-validate`"
      :readonly="parent.isFormReadOnly"
      :rules="rules"

      v-bind="fieldJson"
    />

    <FuFormNote
      v-if="!parent.isFormReadOnly"
      :lang="parent.lang"
      :field-json="fieldJson"
    />
  </div>
</template>

<script>
import { Image as VanImage } from 'vant'
import commonMixins from '../mixins/materials'
import displayMixins from '../mixins/display'
import numericMixins from '../mixins/numeric'
import inputScroll from '../directive/inputScroll/index'
import { isArray, getTenantConfig } from '@fu/core'
import { getCDNhost } from '../utils'
const cdnHost = getCDNhost()

export default {
  name: 'FormItemInput',
  components: { VanImage },
  directives: { inputScroll },
  mixins: [commonMixins, displayMixins, numericMixins],
  data() {
    return {
      getTenantConfig,
      flagMap: {
        ID: `${cdnHost}/assets/country/ID.png`,
        TH: `${cdnHost}/assets/country/TH.png`,
        VN: `${cdnHost}/assets/country/VN.png`
      }
    }
  },
  computed: {

    hasBuiltPrefix() {
      const { prefixType, prefixBuilt } = this.fieldJson

      return prefixType == 3 && isArray(prefixBuilt) && !!prefixBuilt.length
    },
    hasPrefix() {
      const { prefixType, prefix } = this.fieldJson
      return this.hasBuiltPrefix || !!this.dynamicPrefix || (prefixType == 1 && !!prefix)
    },
    hasBuiltSuffix() {
      const { suffixType, suffixBuilt } = this.fieldJson

      return suffixType == 3 && isArray(suffixBuilt) && !!suffixBuilt.length
    },
    hasSuffix() {
      const { suffixType, suffix } = this.fieldJson
      return this.hasBuiltSuffix || !!this.dynamicSuffix || (suffixType == 1 && !!suffix)
    }
  },
  methods: {
    onBlur() {
      if (!this.currentValue) { this.isFocus = false }

      if (!this.fieldJson.filter) { return }

      // 失焦后出发校验
      this.parent.$refs.FuFormMin.validate(`${this.data.name}-validate`)
    }
  }
}
</script>
