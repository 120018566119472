<template>
  <Form
    ref="FuFormMin"
    class="fu-form-min fu-form-min-label-top"
    :class="{
      'readonly': isFormReadOnly,
      'desktop': isDesktop,
      'V1': styleVersion != 'V2',
      'V2': styleVersion == 'V2',
      'split': isGroupSplit
    }"
    @failed="onFailed"
  >
    <!-- 标题 -->
    <p v-if="styleVersion == 'V2' && title" class="fu-form-min-title">{{ title }}</p>

    <!-- 分组暂时模式 -->
    <template v-if="isGroupSplit">
      <FormSplitItem
        v-for="(item, index) in formItems"
        :key="index"
        :datas="formStore.values"
        :column="item"
        :class="splitValidate[item.key] === false ? 'error' : ''"
        @change="onSplitFormChange"
      />
    </template>
    <template v-else>
      <components
        :is="`FuMin${item.renderType}`"
        v-for="(item, index) in formItems"
        :key="index"
        v-model="value[item.prop]"
        :data="item"
        :path="[]"
      />
    </template>

  </Form>
</template>

<script>
import { Form } from 'vant'
import FuMinObject from './materials/object.vue'
import FuMinArray from './materials/array.vue'
import FuMinGroup from './materials/group.vue'
import FormStore from './store/index'
import FormSplitItem from './components/form-split-item.vue'
import { isDesktop } from '../../utils/dispatch'
import { Session } from '@fu/core'

export default {
  name: 'FuFormMin',
  components: { Form, FuMinObject, FuMinArray, FuMinGroup, FormSplitItem },
  props: {
    value: { type: Object, default: () => {} },
    schema: { type: Object, default: () => {} },
    disabled: { type: Boolean, default: false },
    lang: { type: String, default: 'en' },
    extraForm: { type: Object, default: () => { return {} } },

    // 扩展数据
    extendDatas: { type: Object, default: () => { return {} } },

    // 是否跟踪错误字段
    // 即校验后是否滚动到错误字段的位置
    trackErrorField: { type: Boolean, default: false },

    // 测试标签
    test: { type: Boolean, default: false },

    title: { type: String, default: '' }
  },
  data() {
    return {
      splitValidate: [],
      isDesktop,
      tipPopupVisible: false,
      tipConfig: {},

      formStore: {}
    }
  },
  computed: {
    // 表单是否是查看详情的状态
    isFormReadOnly() {
      return this.disabled !== false
    },
    formItems() {
      return this.schema.columns || []
    },
    styleVersion() {
      return this.schema?.fieldJson?.styleVersion || 'V1'
    },
    // 内置组的展示模式
    isGroupSplit() {
      return this.schema?.fieldJson?.groupDisplayMode === 'split'
    }

  },
  created() {
    this.formStore = new FormStore(this.value || {}, { test: this.test })
    // 添加监听 字段值改变则更新表单数据
    // 添加监听 字段值改变则更新表单数据
    this.formStore.subscribe((name) => {
      this.$emit('input', this.formStore.values)
      this.$emit('change', { name, value: this.formStore.values })

      // 每次更新都同步数据给app做缓存
      // if (this.useCache && this.hasRenderingCompleted && this.hasGetAppCacheFirst) {
      //   setAppCache(this.formStore.values)
      // }
    })

    // 关闭probeType属性(传说是因为这个启用监听滚动状态的很耗性能,关闭这个属性滑动就会流畅很多)
    document.addEventListener('touchmove', this.preventDefault, false)
  },
  destroyed() {
    document.removeEventListener('touchmove', this.preventDefault)
  },
  deactivated() {
    document.removeEventListener('touchmove', this.preventDefault)
  },
  mounted() {
    // 清除表单校验
    this.$nextTick(() => {
      setTimeout(() => {
        this.resetValidate()
      })
    })
  },
  methods: {
    preventDefault(e) {
      // Check if 'e' is a valid event object
      if (e instanceof Event) {
        e.preventDefault()
      } else {
        console.error('Invalid event object')
      }
    },
    resetValidate() {
      this.$refs.FuFormMin && this.$refs.FuFormMin.resetValidation()
    },
    async validate() {
      if (this.isGroupSplit) {
        return new Promise((resolve, reject) => {
          let validate = true

          this.formItems.forEach(item => {
            if (['Object', 'Array'].includes(item.renderType)) {
              const { path } = this.$route
              validate = (this.splitValidate[item.key] === true) || !!Session.get(`Ordering-${path}-${item.key}-validate`)

              if (!validate) {
                this.splitValidate[item.key] = false
                return
              }
              // if (this.splitValidate[item.key] !== true) {
              //   this.splitValidate[item.key] = false
              //   validate = false
              //   return
              // }
            }
          })

          // const validate = this.validateSchema()

          if (validate) {
            resolve()
          } else {
            this.$forceUpdate()
          }
        })
      }

      // 这里主要是为了可以触发表单的failed方法
      if (this.trackErrorField) {
        this.$refs.FuFormMin.submit()
      }

      const validate = await this.$refs.FuFormMin.validate()

      // FIXME: 默认校验通过后动态表单缓存数据清除
      if (!validate) {
        // const key = this.cacheKey || this.$route.path
        // dispatchAction({
        //   method: 'removeAppCache',
        //   params: {
        //     key
        //   }
        // })
      }

      return validate
    },

    onFailed({ errors = [] }) {
      const [firstField] = errors

      // 开启错误跟踪并有错误字段的情况下
      if (firstField && this.trackErrorField) {
        this.$refs.FuFormMin.scrollToField(firstField.name)
      }
    },
    onSplitFormChange({ datas, key, validate }) {
      // 更新数据
      this.formStore.setFieldsValue(datas)

      const { path } = this.$route
      Session.set(`Ordering-${path}-${key}-validate`, validate)
      this.splitValidate[key] = validate

      this.$emit('splitChange', datas)
    }
  }
}
</script>

<style lang="scss">
@import './styles/index.scss';
@import './styles/theme.scss';
</style>
