
import { Dialog, Field, CheckboxGroup, Checkbox, RadioGroup, Radio, Stepper, Uploader, Popup, Picker, DatetimePicker, Cascader } from 'vant'
import FuDivider from '@/components/v2/FuDivider'
import DefaultValue from './defaultValue.js'
import RenderRely from './renderRely.js'
import DisabledRely from './disabled.js'

import { Session } from '@/utils/storage'
import { find, propEq, clone } from 'ramda'
import { getFileUrl } from '@/api'
import langMap from '../lang/index'
import { isRegExp } from '../utils/index'
import { validateStrLen, validateInt } from '../utils/validate.js'
import FuFormNote from '../components/note.vue'
import FuFormLabel from '../components/label.vue'
import { sortStr, getNormTimeZone, getAnyTimestamp, getTargetDate } from '../utils/index'
import { getTenantRegion, isNumber } from '@fu/core'

export default {
  components: { Dialog, Field, CheckboxGroup, Checkbox, RadioGroup, Radio, Stepper, Uploader, Popup, Picker, DatetimePicker, Cascader, FuFormNote, FuFormLabel, FuDivider },
  data() {
    return {
      appInfo: Session.get('appInfo') || {},
      ifRender: true,
      visibleForPopup: false,

      isCanScroll: false,

      loading: false,

      isFocus: false
    }
  },
  mixins: [DefaultValue, RenderRely, DisabledRely],
  props: {
    data: { type: Object, default: () => {} },
    // value: { type: [Array, String, Number, Object, Boolean], default: '' },
    path: { type: Array, default: () => [] },
    // 当前遍历的序号
    arrayIndex: { type: Number, default: 0 }
  },
  computed: {
    fieldJson() {
      return this.data.fieldJson || {}
    },
    isSchemaObject() {
      const type = this.data.schema ? this.data.schema.type : 'string'
      return type == 'object' || type == 'array'
    },
    currentPath() {
      const path = []
      if (this.fieldJson.flat) { // 如果需要做扁平化处理则跳过
        // 不做操作
      } else {
        if (this.isFromArray) {
          path.push(this.arrayIndex)
        } else {
          path.push(this.data.key)
        }
      }

      return [
        ...this.path,
        ...path
      ]
    },
    parent() {
      let parent = this.$parent

      if (!parent) {
        return parent
      }
      let parentName = parent.$options.name

      while (parentName !== 'FuFormMin') {
        parent = parent.$parent
        parentName = parent.$options.name
      }
      return parent
    },
    isV2() {
      return this.parent?.styleVersion === 'V2'
    },
    editFields() {
      return this.parent.editFields || []
    },
    canEdit() {
      // 如果不是详情模式则直接可编辑
      if (!this.parent.isFormReadOnly) { return true }
      // 忽略编辑字段
      if (this.parent.ignoreEdit) { return false }
      // 详情模式下 如果有配置 canEdit 则可编辑
      if (this.fieldJson.canEdit) { return true }
      // 如果组件属性 editFields 没有传入可编辑字段则不可编辑
      if (!this.editFields.length) { return false }

      // 如果组件属性 editFields 有传入字段则判断当前字段是否可编辑
      const currentPath = this.currentPath.map(key => {
        if (isNumber(key)) { return '[index]' }
        return key
      }).join('.')

      return this.editFields.some(item => {
        return item.includes(currentPath)
      })
    },

    label() {
      if (!this.data.nameLocal && !this.data.name) { return '' }

      const region = getTenantRegion()

      if (this.parent.lang !== 'en') {
        const localLabel = region === 'VN' ? langMap.vn[this.data.key] || this.data.name : this.data.name
        const finalLabel = this.data.nameLocal || localLabel

        return finalLabel
      } else {
        return this.data.name
      }

      // return this.parent.lang !== 'en' ? this.data.nameLocal || langMap.vn[this.data.key] || this.data.name : this.data.name
    },
    aliasLabel() {
      return this.parent.lang !== 'en' ? this.data.aliasNameLocal || this.data.aliasName : this.data.aliasName
    },
    placeholder() {
      return (this.parent.lang !== 'en' ? this.fieldJson.placeholderLocal : this.fieldJson.placeholder) || ''
    },
    formStore() {
      return this.parent.formStore
    },
    rootData() {
      return this.parent.formStore.values
    },
    optionsLabel() {
      return this.parent.lang == 'en' ? 'label' : 'labelLocal'
    },
    // 字段校验
    rules() {
      if (this.parent.isFormReadOnly) { return [] }

      const rules = []

      const { checkTrigger } = this.fieldJson
      const trigger = this.data.renderType == 'Input' || this.data.renderType == '' ? 'onBlur' : 'onChange'
      const finalTrigger = checkTrigger || trigger

      if (this.fieldJson.required) {
        const messageMap = {
          en: 'is required',
          vi: 'bắt buộc',
          th: '*'
        }
        const currentMessage = messageMap[this.parent.lang] || ''
        const messagePrev = this.aliasLabel || this.label
        const message = this.parent.lang == 'th' ? ` ${currentMessage} “${messagePrev}”` : `“${messagePrev}” ${currentMessage}`

        // 上传类型需要特殊判断
        if (this.data.renderType == 'Upload') {
          rules.push({
            validator: (val) => {
              if (!val || !val.length) { return false }

              return val.every(item => {
                return !!item.fileKey
              })
            }, message
          })
        }

        if (this.data.renderType == 'Aiphoto') { // TODO: 待完善
          rules.push({
            validator: (val) => {
              console.log(val)
              if (!val || !val.length) { return false }

              return val.every(item => {
                return !!item.fileKey
              })
            }, message
          })
        }

        rules.push({ required: true, message, trigger: finalTrigger })

        // 上传类型需要特殊判断
        if (this.data.renderType == 'Upload') {
          rules.push({
            validator: (val) => {
              if (!val || !val.length) { return false }

              return val.every(item => {
                return !!item.fileKey
              })
            }, message
          })
        }
      }

      // schema校验
      if (this.data.schema) {
        const messageMap = {
          en: 'Wrong format',
          vi: 'sai định dạng',
          th: 'รูปแบบไม่ถูกต้อง'
        }
        const { pattern, patternMessage, patternMessageLocal, minimum, maximum, minLength, maxLength } = this.data.schema
        const finalPatternMessage = this.parent.lang == 'en' ? patternMessage : patternMessageLocal

        if (isRegExp(pattern)) {
          // 字符串转正则
          const _pattern = pattern.trim()
          const str = _pattern.replace(/^\/(.*)\/([a-z]*)$/, '$1')
          const args = _pattern.replace(/^\/(.*)\/([a-z]*)$/, '$2')
          const reg = new RegExp(str, args)

          rules.push({ pattern: reg, message: finalPatternMessage || messageMap[this.parent.lang], trigger: finalTrigger })
        }

        // 校验提示语需要的公共数据
        const commonParams = {
          lang: this.parent.lang,
          name: this.label
        }

        // 值大小校验
        if (minimum || maximum) {
          rules.push({
            ...validateInt({
              minimum,
              maximum,
              ...commonParams
            }),
            trigger: finalTrigger
          })
        }

        // 字符长度校验
        if (minLength || maxLength) {
          rules.push({
            ...validateStrLen({
              minLength,
              maxLength,
              ...commonParams
            }),
            trigger: finalTrigger
          })
        }
      }

      // 判断是否有动态规则
      if (this.dynamicRules && this.dynamicRules.message) {
        rules.push({
          ...this.dynamicRules,
          trigger: finalTrigger
        })
      }

      return rules
    },

    // 当前字段绑定的表单值
    currentValue: {
      get() {
        const value = this.formStore.getFieldValue(this.currentPath)

        // 兼容 Same As 配置 isFocus 失效的问题
        if (!!value || value === 0) {
          this.isFocus = true
        }
        return value
      },
      set(value) {
        this.setCurrentValue(value)
      }
    },
    // 当前字段绑定的表单值
    getPreviousValue: {
      get() {
        return this.formStore.getFieldValue(this.currentPath.slice(0, this.currentPath.length - 1))
      },
      set(value) {
        this.setCurrentValue(value)
      }
    },
    // 数字输入框类型专门加一个显示值
    // 主要用来一些输入框会配置格式化的操作
    // 这样可以把显示的值和提交的值区分开来
    numericDisplayValue: {
      get() {
        return this.formatter(this.currentValue)
      },
      set(val) {
        this.currentValue = val
      }
    },
    isNumber() {
      const types = ['integer', 'number']
      if (!this.data.schema) {
        return false
      }
      const currentType = this.data.schema.type

      return types.includes(currentType)
    },
    isTH() {
      const { region } = this.appInfo
      return region == 'TH'
    }
  },
  created() {
    this.initJudgeIfRender()
  },
  mounted() {
    this.initDefaultValue()
    this.initDefaultValueNotify()

    this.initSort()

    this.$nextTick(() => {
      // 清楚表单校验
      this.parent.$refs.FuFormMin && this.parent.$refs.FuFormMin.resetValidation(this.data.name)
    })
  },
  methods: {
    // 针对点击字段弹出层的处理
    onClickFieldForPopup() {
      if (this.data.renderType == 'Date' && this.fieldJson.autoLinking) { return }
      if (this.parent.isFormReadOnly && !this.canEdit) { return }
      if (this.finalDisabled) { return }

      this.visibleForPopup = true
    },
    // 默认值初始化
    async initDefaultValue() {
      const { defaultValueSource, defaultValue, repeat } = this.fieldJson

      // 优先级1 有传值的话就直接赋值
      if (this.currentValue) {
        // 如果是上传组件 则需要额外通过接口去查询文件地址，达到文件预览的效果
        if (this.data.renderType == 'Upload' && this.currentValue && this.currentValue.length) {
          // 判断当前上传字段的类型和已有值的类型是否对应
          const copyPath = clone(this.currentPath)
          const prevPath = copyPath.splice(0, copyPath.length - 1)
          const typePath = [...prevPath, 'attachmentType']
          const dataType = this.formStore.getFieldValue(typePath)

          const attachmentMeta = this.formStore.getFieldValue([...prevPath, 'attachmentMeta']) || []
          // 缓存视频预览图集合
          const thumbnailUrlArray = []

          // 类型值不对应的话则不获取对应的图片
          // 非详情模式则终止
          if (dataType != this.fieldJson.attachmentType && !this.parent.isFormReadOnly) {
            return
          }

          // 正常情况获取对应的图片
          const res = await getFileUrl({
            fileKeys: this.currentValue
          })

          // 视频附件需要额外查询缩略图
          if (this.isVideo) {
            const thumbnailkeys = attachmentMeta.map(item => {
              const { metadata = {}} = item

              return metadata.thumbnailPath
            }).filter(item => item)

            if (thumbnailkeys.length) {
              const thumbnailRes = await getFileUrl({
                fileKeys: thumbnailkeys
              })

              if (res && Array.isArray(thumbnailRes)) {
                thumbnailRes.forEach(item => {
                  thumbnailUrlArray.push(item.fileUrl)
                })
              }
            }
          }

          if (!res || !Array.isArray(res)) { return }

          this.uploader = res.map((item, index) => {
            const { metadata = {}} = attachmentMeta[index] || {}
            const thumbnailUrl = this.isVideo ? thumbnailUrlArray[index] || metadata.thumbnailUrl || this.defaultThumbnailUrl : item.fileUrl

            return {
              status: 'done',
              fileKey: item.fileKey,
              fileUrl: item.fileUrl, // 详情模式下，视频预览需要此字段
              url: thumbnailUrl
            }
          })
        }

        // 签名字段
        if (this.data.renderType == 'Sign' && this.currentValue) {
          const res = await getFileUrl({
            fileKeys: [this.currentValue]
          })

          if (!res || !Array.isArray(res)) { return }

          this.signUrl = res[0]?.fileUrl || ''
        }

        if (this.data.renderType == 'Aiphoto' && this.currentValue) {
          // console.log('this.currentValue', this.currentValue, this.getPreviousValue.attachmentMeta)
          const renewArray = await Promise.all(this.getPreviousValue?.attachmentMeta.map(async (item, index) => {
            if (!item?.attachmentPath?.length) return []
            const { attachmentPath, fileUrl, message, messageLocal, valid, url } = item
            const res = await getFileUrl({
              fileKeys: item?.attachmentPath
            })
            return [{
              fileKey: attachmentPath && attachmentPath.length && attachmentPath[0], message, messageLocal, valid,
              status: 'done',
              url: url || res?.[0]?.fileUrl,
              fileUrl: fileUrl || res?.[0]?.fileUrl
            }]
          }))
          renewArray.map((item, index) => {
            this.$set(this.aiPhotoLists, index, item)
            this.setCurrentValue(index)
          })
          // console.log(renewArray)
        }

        // 强制类型转换
        // 避免int类型获取到的值是 字符串 导致不能匹配
        if (this.data.schema.type == 'integer' && typeof this.currentValue !== 'object') {
          this.currentValue = this.currentValue * 1
        }

        // 泰国编辑状态下设置默认值的时候 EffectiveDate 不符合最小值的情况
        this.handleThEditFormEffectiveDateFeild()

        this.isFocus = true

        return
      }

      // 优先级2 有配置默认值的话就设置默认值
      if (defaultValue !== undefined && defaultValue !== '') {
        if (defaultValueSource == 1) { // 固定默认值
          const finalValue = this.data.schema.type == 'integer' ? defaultValue * 1 : defaultValue

          this.setCurrentValue(finalValue)
        } else if (defaultValueSource == 4) { // 相对默认值
          // 相对默认值目前只有 Date 类型才会有
          // 相对于当前日期做偏移
          const currentDate = new Date()

          // setAssignDate 方法是 Date 组件内的方法
          this.currentValue = this.setAssignDate(defaultValue, 3, currentDate)

          this.isFocus = true
        }

        return
      }

      if (this.data.renderType == 'Slider' && this.fieldJson.range) {
        this.currentValue = []
      }

      if (this.data.renderType == 'Array' && repeat && !this.currentValue) {
        this.currentValue = []
      }

      this.isFocus = !!this.currentValue

      return
    },
    handleThEditFormEffectiveDateFeild() {
      if (!this.parent.isFormReadOnly && this.data.key == 'effectiveDate') {
        const { minDate = '' } = this.dateProps
        const { y, m, d } = getTargetDate(minDate)
        const { zone = 7 } = this.appInfo
        const timezone = getNormTimeZone(zone)
        const minTimes = getAnyTimestamp(`${y}-${m + 1}-${d} 00:00:00`, timezone)
        if (this.currentValue < minTimes) {
          this.setCurrentValueEmpty()
        }
      }
    },

    // 当前字段赋值
    setCurrentValue(value, path) {
      const { filter } = this.fieldJson
      const finalValue = this.formaterValue(filter, value)

      // 赋值优化, 如果值没有变化则不赋值
      const feFieldVal = this.formStore.getFieldValue(path || this.currentPath)
      if (feFieldVal === finalValue) return

      const isInt = ['integer', 'number'].includes(this.data.schema.type)
      this.formStore.setFieldValue(path || this.currentPath, isInt ? finalValue * 1 : finalValue)

      if (!!finalValue || (finalValue === 0 && this.data.renderType !== 'Date')) {
        this.isFocus = true
      }

      // 有 options 选项的则补充相关label
      if (this.fieldJson.options && this.fieldJson.options.length) {
        // 添加
        const labelPath = this.currentPath.map((key, index) => {
          return index == this.currentPath.length - 1 ? `${key}Label` : key
        })
        const labelLocalPath = this.currentPath.map((key, index) => {
          return index == this.currentPath.length - 1 ? `${key}LabelLocal` : key
        })
        const nullValue = { label: '', labelLocal: '' }
        const option = (value || value == 0) ? this.getLabelByValue(value, this.fieldJson.options) || nullValue : nullValue

        this.formStore.setFieldValue(labelPath, option.label)
        this.formStore.setFieldValue(labelLocalPath, option.labelLocal)
      }
    },
    setCurrentValueOCR(value, path, optional) {
      this.formStore.setFieldValue(path, value)
      // 有 options 选项的则补充相关label
      if (optional?.fieldJson.options && optional?.fieldJson.options.length) {
        var options = optional.fieldJson.options
        // 添加
        const labelPath = path.map((key, index) => {
          return index == path.length - 1 ? `${key}Label` : key
        })
        const labelLocalPath = path.map((key, index) => {
          return index == path.length - 1 ? `${key}LabelLocal` : key
        })
        const nullValue = { label: '', labelLocal: '' }
        const option = (value || value == 0) ? this.getLabelByValue(value, options) || nullValue : nullValue

        this.formStore.setFieldValue(labelPath, option.label)
        this.formStore.setFieldValue(labelLocalPath, option.labelLocal)
      }
    },

    // 值的格式化
    formaterValue(formater, value) {
      if (!formater) { return value }
      if (!value) { return value }

      switch (formater) {
        case 'lowercase':
          return value.toLowercase()
        case 'upperCase':
          return value.toUpperCase()
        default:
          return value
      }
    },

    // 通过value 反查对应的label
    getLabelByValue(value, options) {
      if (Array.isArray(value)) {
        const labelOption = value.map(key => {
          return find(propEq('value', key))(options)
        })
        const labels = []
        const labelLocals = []

        labelOption.forEach(item => {
          if (item) {
            labels.push(item.label)
            labelLocals.push(item.labelLocal)
          }
        })

        return {
          label: labels.join(', '),
          labelLocal: labelLocals.join(', ')
        }
      } else {
        return find(propEq('value', value))(options)
      }
    },

    // 内容格式化
    formatter(value) {
      if (value === '' || value === undefined || value == null) { return value }
      if (!this.fieldJson.filter) { return value }

      // 货币格式化
      if (this.fieldJson.filter == 'amount') {
        const lastStr = value.toString().substr(value.length - 1, 1)
        const isLastPoint = lastStr == '.'

        let numValue = value
        if (typeof value !== 'number') { numValue = Number(value) }

        // 这里判断一下输入的最后一个字符是否是小数点
        // 如果是小数点则在转换格式后再把小数点拼接上去
        // 因为上面的 Number 方法会把小数点清掉 不然就无法输入浮点型数字了
        if (isLastPoint) {
          return `${numValue.toLocaleString('en')}${lastStr}`
        } else {
          return numValue.toLocaleString('en')
        }
      }

      return value
    },
    // options 数据排序
    initSort() {
      if (!this.fieldJson.options || !this.fieldJson.options.length) { return }

      this.fieldJson.options = sortStr(this.fieldJson.options, this.fieldJson.sortType, this.optionsLabel, this.isNumber)

      this.fieldJson.cloneOptions = clone(this.fieldJson.options)
    },

    // 输入框字段 获取焦点的时候的处理
    onIptFocus(event) {
      // 使输入框滚动到可视区域
      setTimeout(() => {
        event.target.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
      }, 200)

      if (!this.canEdit) { return }

      this.isFocus = true
    },
    onIptBlur() {
      if (this.currentValue) { return }
      this.isFocus = false
    },
    onClickField() {
      if (!this.canEdit) { return }

      if (this.$refs.inputField) {
        this.$refs.inputField.focus()
      }
    },

    // 判断该字段是否渲染
    initJudgeIfRender() {
      const { show, isHideInDetails, isHideIfNullInDetails } = this.data.fieldJson || {}

      // 详情模式
      if (this.parent.disabled) {
        if (isHideInDetails) {
          this.ifRender = false
        } else {
          if (isHideIfNullInDetails) {
            this.ifRender = !!this.currentValue || this.currentValue === 0
          } else {
            this.ifRender = !!show
          }
        }
      } else { // 表单模式
        this.ifRender = !!show
      }
    }
  }
}
