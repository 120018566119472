/*
 * @Author       : Simba
 * @Date         : 2022-01-24 17:26:06
 * @LastEditors  : Simba
 * @LastEditTime : 2022-03-14 13:47:38
 * @Description  : message
 * @FilePath     : /vn-register/src/components/FuFormMin/utils/request.js
 */
import axios from 'axios'
import { Notify } from 'vant'
import Dao from '@/utils/dao.js'
import { Session } from '@/utils/storage'
import { ORIGIN_BASE_URL, transformConfig } from '@/config'
import { setRandomKey } from '@/utils/index.js'

// create an axios instance
const service = axios.create({
  baseURL: ORIGIN_BASE_URL, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  // timeout: 5000, // request timeout
  // headers: { 'Content-Type': 'application/json' }
  adapter: retryAdapterEnhancer(axios.defaults.adapter)
})

// 重试机制
function retryAdapterEnhancer(adapter, options = {}) {
  // times：全局设置请求重试的次数；
  // delay：全局设置请求延迟的时间，单位是 ms
  const { times = 2, delay = 1000 } = options

  return async (config) => {
    const { retryTimes = times, retryDelay = delay } = config
    let __retryCount = 0
    const request = async () => {
      try {
        return await adapter(config)
      } catch (err) {
        if ('fundebug' in window) {
          window.fundebug.notifyError(err, {
            metaData: {
              errType: '接口重连'
            }
          })
        }

        if (!retryTimes || __retryCount >= retryTimes) {
          return Promise.reject(err)
        }
        __retryCount++
        // 延时处理
        const delayer = new Promise((resolve) => {
          setTimeout(() => {
            resolve()
          }, retryDelay)
        })
        // 重新发起请求
        return delayer.then(() => {
          return request()
        })
      }
    }
    return request()
  }
}

// request interceptor
service.interceptors.request.use(
  config => {
    // config.headers.language = getLanguage()
    const userInfo = Dao.get('userInfo') || {}
    const tempToken = Dao.get('tempToken') || {}
    // 和app通信获取基本信息
    const appInfo = Session.get('appInfo') || {}

    const sessionToken = Session.get('token') || ''

    if (config.isFormData) {
      config.headers['Content-Type'] = 'multipart/form-data;charset=UTF-88'
    }

    // config.headers.accountId = userInfo.accountId

    config.headers.language = appInfo.language || appInfo.lan || 'en-US'
    config.headers.fusetoken = sessionToken || appInfo.token || userInfo.token
    if (window.location.hash.includes('claim/submit/form')) config.headers['x-5a-temp-token'] = tempToken
    // 删除前缀/vn
    config.url = config.url.replace(/^\/?vn\//, '/')

    const { baseURL, url } = transformConfig(config)
    config.baseURL = baseURL
    config.url = url

    config.headers['X-Trace-Id'] = setRandomKey()

    config.url = url + `?_v=${setRandomKey()}`

    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    Dao.set('LastHttpDate', response.headers.date)

    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.code != '200' && !res.resultObj) {
      Notify({
        message: res.message || res.errorCode || res.msg || 'Error',
        type: 'warning'
      })
      return Promise.reject(res)
    } else {
      return res.data || res.resultObj || res.result || { status: true }
    }
  },
  error => {
    if ('fundebug' in window) {
      window.fundebug.notifyError(error, {
        metaData: {
          errType: '接口报错'
        }
      })
    }

    if (error.response) {
      // server responded with a status other than 200
      console.log('error.response.data', error.response.data)
      console.log('error.response.status', error.response.status)
      console.log('error.response.headers', error.response.headers)
    } else if (error.request) {
      // no response received
      console.log('error.request', error.request)
    } else {
      // something happened in setting up the request
      console.log('error message', error.message)
    }
    console.log('error.config', error.config)

    // 请求失败、错误处理回调
    const originalRequest = error.config
    if (error.code == 'ECONNABORTED' && error.message.indexOf('timeout') != -1 && !originalRequest._retry) {
      console.log('超时错误')
    }

    Notify({
      message: error.message,
      type: 'warning'
    })
    return error
  }
)

export default service
