<template>
  <div v-if="ifRender && !isTravelHooksHidden" class="fu-form-min-array">
    <!-- 分割组件 -->
    <FuDivider v-if="isV2 && fieldJson.divider" :type="fieldJson.dividerType || 'divider'" />

    <!-- 普通展示 -->
    <template v-if="fieldJson.repeat || hiddenCollapse || parent.isFormReadOnly">
      <h3 v-if="!parent.isFormReadOnly && isRenderHeader && (label || isShowArrayBtn)" class="fu-form-min-tit">
        <!-- 自定义 label -->
        <FuFormLabel
          v-if="label"
          :parent="parent"
          :label="label"
          :field-json="fieldJson"
        />

        <Button
          v-if="isShowArrayBtn"
          round
          size="mini"
          type="info"
          icon="plus"
          native-type="button"
          @click.stop="onAddArrayItem"
        />
      </h3>

      <!-- 子标题 -->
      <p v-if="isV2 && aliasLabel" class="fu-min-form-aliaslabel">{{ aliasLabel }}</p>

      <div class="fu-form-min-array-con">
        <components
          :is="`FuMin${item.renderType}`"
          v-for="(item, index) in arrayItemList"
          :key="index"

          :data="item"
          :path="currentPath"

          :is-from-array="true"
          :is-show-title="isShowTitle"
          :is-repeat-item="fieldJson.repeat"
          :array-index="index"
          :item-start-index="startIndex"

          :same-as-path="fieldJson.itemValueSameAs"
          :select-same-as-item-index="selectSameAsItemIndex"
          :same-as-show="fieldJson.itemSameAsShow"
          :same-as-disabled="fieldJson.itemSameAsDisabled"

          @onRemove="onRemoveArrayItem"
          @onSameAsChange="onSameAsChange"
        />
      </div>
    </template>

    <!-- 折叠面板 -->
    <Collapse
      v-else
      v-model="activeNames"
      :border="false"
    >
      <CollapseItem
        :title="label"
        name="item"
        disabled
      >
        <!-- 自定义 label -->
        <template v-if="fieldJson.tip" slot="title">
          <FuFormLabel
            :parent="parent"
            :label="label"
            :field-json="fieldJson"
          />
        </template>

        <p v-if="isV2 && aliasLabel" class="fu-min-form-aliaslabel">{{ aliasLabel }}</p>

        <div class="fu-form-min-array-con">
          <components
            :is="`FuMin${item.renderType}`"
            v-for="(item, index) in arrayItemList"
            :key="index"
            :data="item"
            :path="currentPath"

            :is-from-array="true"
            :is-show-title="isShowTitle"
            :is-repeat-item="fieldJson.repeat"
            :array-index="index"
            :item-start-index="startIndex"

            :same-as-path="fieldJson.itemValueSameAs"
            :select-same-as-item-index="selectSameAsItemIndex"
            :same-as-show="fieldJson.itemSameAsShow"
            :same-as-disabled="fieldJson.itemSameAsDisabled"

            @onSameAsChange="onSameAsChange"
          />
        </div>
      </CollapseItem>
    </Collapse>

    <FuFormNote
      v-if="!parent.isFormReadOnly"
      :lang="parent.lang"
      :field-json="fieldJson"
    />
  </div>
</template>

<script>
import commonMixins from '../mixins/materials'
import displayMixins from '../mixins/display'
const FuMinObject = () => import('./object.vue')
const FuMinArray = () => import('./array.vue')
import { Button, Collapse, CollapseItem } from 'vant'

// 特殊业务代码
import TravelHooks from '../hooks/travel'

export default {
  name: 'FuMinArray',
  components: { Button, Collapse, CollapseItem, FuMinObject, FuMinArray },
  mixins: [commonMixins, displayMixins, TravelHooks],
  props: {
    hiddenCollapse: { type: Boolean, default: false }
  },
  data() {
    return {
      activeNames: ['item'],
      isRenderHeader: true,
      selectSameAsItemIndex: null
    }
  },
  computed: {
    // 真正渲染的子节点
    arrayItemList() {
      // 附件类型直接返回columns配置项
      if (this.data.key == 'attachments') {
        return this.data.columns
      }

      // 业务逻辑的特殊处理
      if (this.itemsNumForTravel) {
        return Array.from({ length: this.itemsNumForTravel }, () => {
          return this.data.columns[0]
        })
      } else {
        if (this.currentValue && this.currentValue.length) {
          return Array.from({ length: this.currentValue.length }, () => {
            return this.data.columns[0]
          })
        } else {
          return this.data.columns
        }
      }
    },
    // title 的下标从多少开始
    startIndex() {
      const { itemIndex, itemIndexStartFrom } = this.fieldJson

      if (!itemIndex) { return null }

      return itemIndexStartFrom || 0
    },
    // 是否显示title
    isShowTitle() {
      const { repeat, extraConfig } = this.fieldJson

      return !!repeat || (!!extraConfig && !!extraConfig.dynamicLimitForTravel)
    },

    isShowArrayBtn() {
      return this.fieldJson.repeat && this.currentValue && this.currentValue.length < this.fieldJson.repeatLimit
    }

  },
  created() { },
  methods: {
    // 添加一组 item
    onAddArrayItem() {
      if (this.fieldJson.repeatLimit && this.currentValue && this.currentValue.length == this.fieldJson.repeatLimit) {
        return
      }

      this.currentValue.push({})
      // 清楚表单校验
      this.$nextTick(() => {
        this.parent.$refs.FuFormMin && this.parent.$refs.FuFormMin.resetValidation()
      })
    },
    // 响应子项里面用户点击删除的操作
    onRemoveArrayItem(index) {
      this.currentValue.splice(index, 1)
    },
    // 响应子项里面配置了 Same As 的时候，用户点击的处理
    // 需要缓存子项的下标并下发到各个子项，目的使其他项的Same As隐藏
    onSameAsChange({ value, index }) {
      this.selectSameAsItemIndex = value ? index : null
    }
  }
}
</script>

<style lang="scss" scoped>
.fu-form-min-tit,
.van-cell__title {
  .van-button {
    overflow: hidden;
  }
}
</style>
