<template>
  <div
    v-if="ifRender"
    class="fu-min-form-item"
    :class="{edit: canEdit}"
  >
    <!-- 分割组件 -->
    <FuDivider v-if="isV2 && fieldJson.divider" :type="fieldJson.dividerType || 'divider'" />

    <!-- V2 版本的标题 -->
    <p v-if="isV2 && label" class="fu-form-v2-label">{{ label }}</p>
    <!-- 子标题 -->
    <p v-if="isV2 && aliasLabel" class="fu-min-form-aliaslabel">{{ aliasLabel }}</p>
    <Field
      class="no-border"
      :label="(fieldJson.isGroup && !isV2) ? label : ''"
      :placeholder="placeholder"
      :name="data.name + path"
      :rules="rules"
      v-bind="fieldJson"
    >
      <!-- 自定义 label -->
      <template v-if="fieldJson.tip" slot="label">
        <FuFormLabel
          :parent="parent"
          :label="label"
          :field-json="fieldJson"
        />
      </template>

      <template #input>
        <RadioGroup
          v-model="currentValue"
          direction="horizontal"
          v-bind="fieldJson"
          :disabled="parent.isFormReadOnly || fieldJson.disabled"
          @change="onChange"
        >
          <Radio
            v-for="(item, index) in options"
            :key="index"
            :name="item.value"
            :shape="isGroup ? 'round' : 'square'"
            checked-color="#C82A21"
            @click="onClick"
          >{{ item[optionsLabel] }}</Radio>
        </RadioGroup>
      </template>
    </Field>

    <FuFormNote
      v-if="!parent.isFormReadOnly"
      :lang="parent.lang"
      :field-json="fieldJson"
    />

  </div>
</template>

<script>
import commonMixins from '../mixins/materials'
import displayMixins from '../mixins/display'
import optionsSourceMixins from '../mixins/optionsSource'
import optionsRelyMixins from '../mixins/optionsRely'

export default {
  name: 'FormItemSwitch',
  mixins: [commonMixins, displayMixins, optionsSourceMixins, optionsRelyMixins],
  data() {
    return {
      changed: false
    }
  },
  computed: {
    isGroup() {
      return this.fieldJson.isGroup
    },
    options() {
      if (this.isGroup) {
        return this.fieldJson.options
      } else {
        return [this.fieldJson.options[0]]
      }
    },
    singleValue() {
      return this.fieldJson.options[1] ? this.fieldJson.options[1].value : ''
    }
  },
  methods: {
    // 添加标记
    onChange() {
      this.changed = true
    },
    // single 模式下支持点击取消
    onClick() {
      if (this.isGroup) { return }
      if (this.changed) {
        this.changed = false
        return
      }

      if (this.currentValue != this.singleValue) {
        this.currentValue = this.singleValue
      }
    }
  }
}
</script>
