<template>
  <div v-if="ifRender" class="fu-min-form-item">
    <Field
      :value="fieldShowValue"
      readonly
      clickable
      rows="1"
      autosize
      type="textarea"
      :name="data.name + path"
      :rules="rules"
      :label="label"
      :placeholder="placeholder"
      right-icon="arrow-down"
      v-bind="fieldJson"
      @click="onClickFieldForPopup"
    >
      <!-- 自定义 label -->
      <template v-if="fieldJson.tip" slot="label">
        <FuFormLabel
          :parent="parent"
          :label="label"
          :field-json="fieldJson"
        />
      </template>
    </Field>

    <FuFormNote
      v-if="!parent.isFormReadOnly"
      :lang="parent.lang"
      :field-json="fieldJson"
    />

    <!-- lock-scroll 这里设置成false 是因为在真机上会锁定地址栏的滑动 -->
    <Popup
      v-model="visibleForPopup"
      style="height: 90%"
      class="fu-mini-form-address-popup"
      position="bottom"
      get-container="body"
      :lock-scroll="true"
      round
      :safe-area-inset-bottom="true"
      :close-on-popstate="true"
    >

      <div v-if="loading" class="fu-form-min-address-overlay">
        <Loading />
      </div>

      <Tabs v-model="activeTab" @change="onChangeTab">
        <Tab
          v-for="item in addressTabList"
          :key="item.label"
          :title="item.label"
          :name="item.value"
        />

        <!-- 搜索框 -->
        <Search
          v-if="fieldJson.search"
          v-model="searchVal"
          :placeholder="$t('placeholderSearch')"
          @input="onSearchInput"
        />

        <!-- 空数据展示 -->
        <Empty v-if="isEmpty" />

        <!-- 索引模式 -->
        <div v-if="fieldJson.indexBar" class="fu-min-form-address-list">
          <!-- 索引数据 -->
          <IndexBar v-for="(option, key) in currentTabData" :key="key">
            <IndexAnchor :index="key" />

            <Cell
              v-for="opt in option"
              :key="opt.text"
              class="fu-min-form-address-cell"
              :class="{'active': opt.code == selectValue[activeTab]}"
              :border="false"
              :title="opt.text"
              @click="onClickCell(opt)"
            >
              <!-- 使用 right-icon 插槽来自定义右侧图标 -->
              <template v-if="opt.code == selectValue[activeTab]" #right-icon>
                <Icon name="success" />
              </template>
            </Cell>
          </IndexBar>
        </div>

        <!-- 正常的列表展示 -->
        <div v-else class="fu-min-form-address-list">
          <Cell
            v-for="opt in currentTabData"
            :key="opt.text"
            class="fu-min-form-address-cell"
            :class="{'active': opt.code == selectValue[activeTab]}"
            :border="false"
            :title="opt.text"
            @click="onClickCell(opt)"
          >
            <!-- 使用 right-icon 插槽来自定义右侧图标 -->
            <template v-if="opt.code == selectValue[activeTab]" #right-icon>
              <Icon name="success" />
            </template>
          </Cell>
        </div>
      </Tabs>
    </Popup>
  </div>
</template>

<script>
import commonMixins from '../mixins/materials'
import displayMixins from '../mixins/display'
import { Loading, Tab, Tabs, Search, IndexBar, IndexAnchor, Cell, Icon, Empty } from 'vant'
import request from '../utils/request'
import { clone } from 'ramda'
import Dao from '@/utils/dao'
// import { sortStr } from '../utils/index'

export default {
  name: 'FormItemSelect',
  components: { Loading, Tab, Tabs, Search, IndexBar, IndexAnchor, Cell, Icon, Empty },
  mixins: [commonMixins, displayMixins],
  data() {
    return {
      loading: false,
      searchVal: '',
      activeTab: 'province',
      // 数据缓存
      cachePool: {},

      // 所有tab的数据缓存
      allTabDatas: {},
      currentTabData: {},
      tabIndex: {
        province: 1,
        city: 2,
        district: 3
      },

      // 选中的提交数据
      selectValue: {},
      // 选中的节点信息
      selectData: {
        province: {},
        city: {},
        district: {}
      },

      tabKeys: ['province', 'city', 'district'],
      // 当前tab的下一个tab
      currentTabNext: {
        province: 'city',
        city: 'district'
      }
    }
  },
  computed: {
    fieldShowValue() {
      const address = []

      const { province, provinceName, provinceNameLocal, city, cityName, cityNameLocal, district, districtName, districtNameLocal } = this.currentValue || {}
      const isEn = this.parent.lang == 'en'

      // VN-4594
      // 动态表单的地址字段省市区展示顺序倒过来显示：区市省
      if (district) { address.push(isEn ? districtName : (districtNameLocal || districtName)) }
      if (city) { address.push(isEn ? cityName : (cityNameLocal || cityName)) }
      if (province) { address.push(isEn ? provinceName : (provinceNameLocal || provinceName)) }

      return address.join(' , ')
    },
    levels() {
      return this.fieldJson.levels || 3
    },
    addressTabList() {
      const nameKey = this.parent.lang == 'en' ? 'Name' : 'NameLocal'

      return Array.from({ length: this.levels }).map((item, index) => {
        return {
          label: this.fieldJson[`${this.tabKeys[index]}${nameKey}`] || this.$t(this.tabKeys[index]),
          value: this.tabKeys[index]
        }
      })
    },
    isEmpty() {
      if (Array.isArray(this.currentTabData)) {
        return !this.currentTabData.length
      } else {
        return !Object.keys(this.currentTabData).length
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.getLevelAddress()
    },

    async getLevelAddress(option = {}) {
      const { code = null, uid = null } = option
      const whereParamId = uid || code || null
      const cacheKey = whereParamId || this.activeTab

      // 判断缓存池中是否有缓存数据
      // 有的话直接返回缓存数据
      if (this.cachePool[cacheKey] && !!this.cachePool[cacheKey].length) {
        this.allTabDatas[this.activeTab] = clone(this.cachePool[cacheKey])

        this.currentTabData = this.allTabDatas[this.activeTab]
        return
      }

      this.loading = true

      let url = `/pro/pfm/codetable/list`
      if (window.location.href.includes('/claim')) url = '/pro/claim/temp/codetable/list'
      const token = Dao.get('tempToken')
      const headers = {
        appCode: 'IDP_BOSS',
        'x-5a-temp-token': token ?? ''
      }
      const requestParams = {
        url: url,
        method: 'post',
        data: {
          codeTable: this.fieldJson.codeTable || 'vn_region',
          whereParam: [whereParamId, this.tabIndex[this.activeTab]]
        }
      }
      if (window.location.href.includes('/claim')) requestParams.headers = headers
      const res = await request(requestParams)
      this.loading = false

      if (!res) { return }

      // 如果当前层级没有数据 则直接关闭弹窗
      if (!res.length) {
        const { isEndLevel } = option
        // 如果非最后节点则需要把下层节点的信息清空
        if (!isEndLevel) {
          delete this.selectValue[this.activeTab]
          delete this.selectValue[`${this.activeTab}Name`]
          delete this.selectValue[`${this.activeTab}NameLocal`]
        }
        this.onFinish()
      }

      const nodes = res.map(item => {
        // 泰国那边会返回uid 并通过uid去查询下级数据
        // 越南则是通过 code
        const { uid = '', code, value, valueLocal } = item
        const levelItem = {
          text: this.parent.lang !== 'en' ? valueLocal || value : value,
          code,
          uid,
          value,
          valueLocal,
          isEndLevel: (this.levels == 3 && this.activeTab == 'district') || (this.levels == 2 && this.activeTab == 'city') || (this.levels == 1 && this.activeTab == 'province')
        }

        return levelItem
      })

      // 排序
      // const finalNodes = sortStr(nodes, this.fieldJson.sortType, 'text')

      this.allTabDatas[this.activeTab] = this.setIndexBarNodes(nodes)
      this.currentTabData = this.allTabDatas[this.activeTab]

      // 每次接口请求的数据都放入缓存池
      this.cachePool[cacheKey] = clone(this.allTabDatas[this.activeTab])

      this.$forceUpdate()
    },

    // 生成 索引数据
    setIndexBarNodes(nodes) {
      // 未开索引模式则直接返回数组
      if (!this.fieldJson.indexBar) { return nodes }

      // 以下是索引模式数据分类
      const barRes = {}

      // 根据首字母排序 生成分类数据
      nodes.forEach(item => {
        const { text } = item
        // 获取首字母来分类
        const textFirst = text.slice(0, 1).toUpperCase()

        if (barRes[textFirst]) {
          barRes[textFirst].push(item)
        } else {
          barRes[textFirst] = [item]
        }
      })

      return barRes
    },

    // 点击单项数据
    async onClickCell(opt = {}) {
      this.searchVal = ''
      const { isEndLevel, code, value, valueLocal } = opt
      this.selectValue[this.activeTab] = code
      this.selectValue[`${this.activeTab}Name`] = value
      this.selectValue[`${this.activeTab}NameLocal`] = valueLocal

      if (isEndLevel) { // 是最后的节点， 直接收起弹窗操作结束
        this.onFinish()
      } else { // 非最后节点，查询下级数据
        // tab 自动跳转下一个
        this.activeTab = this.currentTabNext[this.activeTab]
        // 查询下级数据
        await this.getLevelAddress(opt)
      }
    },

    // 最终确认
    onFinish() {
      this.visibleForPopup = false

      this.currentValue = clone(this.selectValue)
    },

    onChangeTab(name) {
      this.searchVal = ''
      this.currentTabData = this.allTabDatas[name] || {}
    },

    // 搜索框
    onSearchInput(val) {
      this.clearTimer()

      this.timer = setTimeout(() => {
        this.handleFilter(val)
      }, 500)
    },
    clearTimer() {
      this.timer && clearTimeout(this.timer)
    },

    handleFilter(key) {
      const options = clone(this.allTabDatas[this.activeTab])
      // 正常模式
      if (Array.isArray(this.currentTabData)) {
        var filterOptions = []
        const keyLen = key.length

        if (key && keyLen > 0) {
          filterOptions = options.filter(item => {
            const { text } = item
            return text.toLowerCase().includes(key.toLowerCase())
            // return text.substr(0, keyLen).toLowerCase() == key.toLowerCase()
          })
        } else {
          filterOptions = clone(this.allTabDatas[this.activeTab])
        }

        this.currentTabData = filterOptions
      } else { // 索引模式
        const firstStr = key.slice(0, 1).toUpperCase()

        // 为空则显示全部
        if (!key) {
          this.currentTabData = clone(this.allTabDatas[this.activeTab])
        }

        if (!options[firstStr]) { return {} }

        const filterOptions = {}
        filterOptions[firstStr] = options[firstStr].filter(item => {
          const { text } = item
          // return text.includes(key)
          return text.toLowerCase().includes(key.toLowerCase())
        })

        this.currentTabData = filterOptions
      }
    }
  }
}
</script>

<style lang="scss">
.fu-form-min-address-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 111;
}

.fu-mini-form-address-popup {
  .van-tabs__content {
    padding: 0 20px;
  }
  .van-tabs {
    height: 100%;
  }
}

.fu-min-form-address-cell.active {
  color: #ee0a24;
  font-weight: 500;
}

.fu-min-form-address-list {
  position: absolute;
  top: 50PX;
  left: 0;
  right: 0;
  bottom: 0;

  overflow-y: auto;
}
.van-search + .fu-min-form-address-list {
  top: 110PX;
}
</style>。

