<template>
  <div
    v-if="isShowTipCard"
    class="fu-v2-alert"
    :class="type"
  >
    <!-- 左侧 icon -->
    <Icon
      v-if="icon"
      class="fu-v2-alert-icon"
      :name="icon"
    />

    <!-- 中间内容 -->
    <div class="fu-v2-alert-con">
      <p v-if="title" class="fu-v2-alert-title">{{ title }}</p>

      <p class="fu-v2-alert-text"> {{ text }}</p>
      <slot />

      <p
        v-if="handle"
        class="fu-v2-alert-handle"
        @click="onClickHandle"
      >{{ handle }}</p>
    </div>

    <!-- 右侧关闭按钮 -->
    <Icon
      v-if="close"
      class="fu-v2-alert-icon"
      name="cross"
      @click="onClose"
    />
  </div>
</template>

<script>
import { isFunction } from '@fu/core'
import { Icon } from 'vant'

export default {
  name: 'FuTipV2',
  components: { Icon },
  props: {
    title: { type: String, default: '' },
    text: { type: String, default: '' },
    icon: { type: String, default: 'info' },
    type: { type: String, default: 'info' },
    close: { type: Boolean, default: false },
    handle: { type: String, default: '' },
    onHandle: { type: [Function, String], default: '' }
  },
  data() {
    return {
      isShowTipCard: true
    }
  },
  computed: {},
  activated() {},

  methods: {
    onClose() {
      this.isShowTipCard = false
    },

    // 操作方法
    async onClickHandle() {
      if (isFunction(this.onHandle)) {
        const res = await this.onHandle()
        if (res === false) { return }
      }

      this.onClose()
    }
  }

}
</script>

<style lang="scss">
.fu-v2-alert + .fu-v2-alert {
  margin-top: 10px;
}
.fu-v2-alert {
  display: flex;
  padding: 10px;
  border: 1px solid #000;
  border-radius: 8px;
  font-size: 12px;
  color: #000;
  background-color: #F5F5F5;

  &.danger {
    border-color: #FC5555;
    background-color: #FFF2F1;
    color: #FC5555;
  }

  &.warning {
    border-color: #E28800;
    background-color: #FFF6E8;
    color: #E28800;
  }

  &.primary {
    border-color: #08B752;
    background-color: #EEFFF5;
    color: #08B752;
  }

  .fu-v2-alert-icon {
    font-size: 14px;
    flex-shrink: 0;
    width: 24px;
    text-align: center;
  }

  .fu-v2-alert-icon + .fu-v2-alert-con,
  .fu-v2-alert-con + .fu-v2-alert-icon {
    margin-left: 5px;
  }
  .fu-v2-alert-title {
    margin-bottom: 6px;
    font-weight: 600;
  }
  .fu-v2-alert-text {

  }
  .fu-v2-alert-handle {
    margin-top: 10px;
    font-weight: 600;
    font-size: 12px;
    text-decoration: underline;
  }
}

.fu-v2-alert + .fu-v2-card {
  margin-top: 20px;
}
</style>
